.drop-down {
  border-radius: var(--rounded-corners);
  width: 100%;
  position: absolute;
  text-transform: var(--title-uppercase);
  background-color: $color5;
  top: 0;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  display: flex;
  flex-direction: column;
  @media (--mobile) {
  border-radius: var(--rounded-corners-sm);
  }
  &__wrapper {
    margin: 0 0 13px 0;
    width: 100%;
    font: var( --minor-header);
    text-transform: var(--minor-header-uppercase);
    position: relative;
    height: 60px;
    user-select: none;
    @media (--mobile) {
      height: 50px;
      font-size: 12px;
      margin: 0 0 8px 0;
    }
  }

  &__header {
    padding: 21px 20px 18px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    min-height: 50px;
    @media (--tablet-sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (--mobile) {
      padding: 15px 15px 14px;
    }
    @media (--mobile-sm) {
      padding: 15px 10px 14px;
    }

    &__selected {
      width: calc(100% - 50px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span.selected {
        margin-left: 5px;
        font-weight: 700;
        @media only screen and (max-width: 599px) {
          margin-left: 0;
        }
      }

      @media (--mobile) {
        font-size: 12px;
        width: calc(100% - 80px);
      }
    }
  }
  &__see-more-header {
    position: absolute;
    background-color: $color5;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 15px;
    border-radius: var(--rounded-corners-sm);
    line-height: normal;
    border: var(--border-thin) $gColorBorder1;
    font-size: 12px;
    font-weight: 700;
    z-index: 3;
    @media (--mobile-md) {
      font-size: 10px;
      padding: 2px 10px;
    }
    @media (--mobile-sm) {
      display: none;
    }
  }
  &__label {
    margin: 0 30px 0 10px;
    color: var(--muted);
    width: 105px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    font-weight: var(--font-weight-regular);
    @media (--mobile) {
      width: 95px;
      margin: 0 15px 0 10px;
    }
    @media (--mobile-sm) {
      margin: 0 10px;
    }
  }
  &__arrow {
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
    height: 12px;
    width: 12px;
    fill: var(--dark);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &--open {
      transform: rotate(180deg);
    }
  }

  &__open {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    z-index: 11;
  }

  &--reverse {
    top: auto;
    bottom: 1px; // 1px to avoid jitter created by border
    flex-direction: column-reverse;

    .drop-down__body--active {
      padding: 10px 0 0 0;
    }
  }

  &__body {
    margin: 0;
    overflow-x: hidden;
    max-height: 300px;
    padding: 0;
    transition: margin 200ms cubic-bezier(0.4, 0, 0.2, 1), padding 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &__mobile-overide {
      display: none;
      @media only screen and (pointer: coarse) and (max-width: ($desktop - 1px)) {
        display: block;
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &--active {
      padding-bottom: 10px;
    }

    font-weight: 400;

    &__item {
      text-transform: initial;
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
      padding: 5px 20px;
      overflow: hidden;
      /* autoprefixer: ignore next */
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      gap: 25px;
      justify-content: space-between;

      &:hover {
        background-color: $gColorbg3;

        .product-card__variants__image-variant {
          box-shadow: 0 0 0 1px $color1;
        }

        .product-card__variants__image-variant__active {
          box-shadow: 0 0 0 2px $color1;
          @media (--mobile) {
            box-shadow: 0 0 0 2px $color1;
          }
        }
        @media (--no-hover) {
          background-color: transparent;
        }
      }

      .product-card__variants__image-variant {
        margin: 0 10px 0 0 !important;
        height: 50px;
        width: 50px;
        border-radius: 4px;
        position: relative;
        min-width: 50px;
      }

      @media (--mobile) {
        font-size: 12px;
        padding: 4px 2px;
      }
    }
  }
}

