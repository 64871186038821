.productlist {
  list-style: none;
  overflow: hidden;
  display: grid;
  float: none;
  gap: 15px;

  // Allow shadows to overflow container
  margin: -10px;
  padding: 10px;
  grid-template-columns: repeat(5, minmax(0, 1fr));

  @media (--desktop-lg) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media (--tablet) {
    gap: 10px;
  }
  @media (--mobile) {
    gap: 5px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1200px) and (min-width: 591px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media only screen and (max-width: 590px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__filter {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @media (--desktop-lg) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    @media only screen and (max-width: 1200px) and (min-width: 961px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media only screen and (max-width: 960px) and (min-width: 768px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media only screen and (max-width: 767px) and (min-width: 591px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media only screen and (max-width: 590px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.productbox__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  gap: 15px;

  @media (--tablet) {
    gap: 10px;
  }
  @media (--mobile) {
    gap: 5px;
  }

  &__categories {
    display: flex;
    flex-wrap: nowrap;
  }

  &__nav {
    background-color: transparent;
    border: var(--border-medium) var(--primary-color);
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: var(--rounded-corners-circle-or-square);
    padding: 0 0 0 2px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: var(--primary-color);
      cursor: pointer;
      svg {
        fill: var(--color-on-primary-color);
      }
    }
    svg {
      fill: var(--primary-color);
      transform: rotate(270deg);
      height: 12px;
      width: 12px;
    }
    &__previous {
      padding: 0 2px 0 0;
      svg {
        transform: rotate(90deg);
      }
    }

    &__wrapper {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      bottom: 6px;
      .none-active {
        border-color: var(--muted);
        svg {
          fill: var(--muted);
        }
        &:hover {
          background-color: transparent;
          border-color: var(--muted);
          svg {
            fill: var(--muted)
          }
        }
      }
      @media (--tablet) {
        right: 30px;
      }
      @media (--mobile) {
        display: none;
      }
    }
  }
}

.product-card {
  &__content {
    &__alternatives {
      background-color: $colorBg10;
      .productbox:last-of-type {
        margin: 0;
      }
      .productbox__slider {
        display: none;
      }
      @media (--mobile) {
        background-color: $colorBg2;
        .productbox__slider {
          display: block;
          &__indicator {
            left: calc(50% - 20px);
          }
        }
      }
    }
    &__accessories {
      background-color: var(--secondary-background-color);
      border-color: var(--secondary-grey);
      .design-lavpris &, .design-lampeguru &, .design-completvvs &, .design-vvskupp &  {
        background-color: var(--white);
        border-bottom: var(--border-thin) var(--border);
      }
    }
    &__product-serie {
      @media (--tablet-and-above) {
        background-color: $color1;
        h2,
        h3 {
          color: $color5;
        }
        .productbox__list__nav {
          border-color: $color5;
          svg {
            fill: $color5;
          }
          &:hover {
            background-color: $color5;
            svg {
              fill: $color1;
            }
          }
        }
        .none-active {
          &:hover {
            background-color: transparent;
            svg {
              fill: $color5;
            }
          }
        }
      }
    }
    &__others-are-looking-at {
      background-color: $colorBg10;
      @media (--mobile) {
        background-color: $colorBg2;
      }
    }
    &__body {
      &__productbox__list {
        padding: 15px 0;
        white-space: nowrap;
        flex-wrap: nowrap;
        display: flex;
        overflow: hidden;
        overflow-y: hidden;
        overflow-x: hidden;
        @media only screen and (min-width: 1515px) {
          overflow: initial;
          overflow-y: initial;
          overflow-x: initial;
        }
        @media (--tablet-and-less) {
          padding: 10px 0;
        }
        @media (--tablet-lg) {
          padding-right: 40px;
          padding-left: 40px;
        }
        @media (--tablet-sm) {
          padding-right: 30px;
          padding-left: 30px;
        }
        @media only screen and (pointer: coarse) {
          user-select: none;
        }
        @media (--mobile) {
          padding: 10px 0 5px 15px;
        }
        &__alternatives {
          padding-top: 88px;
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
          justify-content: center;
          @media (--tablet) {
            .productbox__list {
              display: flex;
            }
          }
          @media (--mobile) {
            padding: 65px 0 15px 15px;
          }
        }
      }
    }
  }
}
