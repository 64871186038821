@import "~styles/utils/media-queries";

.icon {
  --icon-color: var(--muted);

  height: 40px;
  width: 40px;
  margin: 15px 15px 0;

  svg {
    height: 100%;
    width: 100%;
    fill: var(--icon-color);
  }

  @media (--tablet-sm) {
    height: 35px;
    width: 35px;
  }

  @media (--mobile) {
    height: 30px;
    width: 30px;
  }
}

.info {
  --icon-color: var(--info-color);

  svg {
    transform: rotate(180deg);
  }
}

.error {
  --icon-color: var(--error-color);
}

.warning {
  --icon-color: var(--white);
}

.success {
  --icon-color: var(--success-color);
}
