// Custom path for Bootstrap font
$icon-font-path: "../fonts/";

// Vendor
@import "vendor/_bootstrap-custom";

// Custom SCSS
@import "_vars";
@import "_mixins";
@import "_btn";
@import "_global";
@import "_offsets";
@import "_alert";
@import "_admintools";
@import "_cms";
@import "_parallax";
@import "_admintoolaointern";
@import "_partners";
@import "_icon";
@import "_cookie-information";

//product
@import "views/productlist/list";

// Views
@import "views/_header";
@import "views/_footer";
@import "views/_review-slider.scss";
@import "views/_usp";
@import "views/_pay-methods";
@import "views/_breadcrumb";
@import "views/_contact-block";
@import "views/_social-follow";
@import "views/_splash";
@import "views/_algolia-autocomplete";
@import "views/_mosaic";
@import "views/_account";
@import "views/_topbanner";
@import "views/_blockbanner";
@import "views/_dropdown";
@import "views/_manufacturerslist";
@import "views/_videotopbanner";
@import "views/_campaign_banner";
@import "views/_brandbox";
@import "views/_newsletter";
@import "views/_missing-consent";
@import "views/image-boxes";
@import "views/_step_by_step_image";
@import "views/articles/_articles";
@import "views/articles/_social-media";
@import "views/articles/_points";
@import "views/articles/_step_by_step";
@import "views/articles/_offer_banner";
@import "views/articles/_bodycontent";
@import "views/_pageheader";
@import "views/_halfwidth";
@import "views/animated-arrow";
@import "views/countdown-timer";

//Article
@import "views/article/article";

@import "react-transitions";

// Import the print style
@media print {
  @import "./_print.scss";
}

@import "splash/_splash";
