.aa-no-hits {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px 0;

  ul {
    margin: 5px 0 0 0;
    padding: 0;
  }

  li {
    list-style: none;
    padding: 3px 0;
    font-size: 14px;
    @media (--mobile) {
      font-size: 12px;
    }

    a {
      text-decoration: none;
      color: $gColorFont2;
      &:hover {
        color: $gColorFont1;
      }
    }
  }
}


.aa-datasets:has(.aa-suggestions) {
  width: min(1280px, calc(100vw - 10px));
}

.aa-datasets:has(.aa-no-hits) {
  width: min(600px, calc(100vw - 10px));
}

.aa-datasets-not-products {
  background-color: var(--primary-background-color);
  border-radius: 0 4px 4px 0;
  padding: 0;
  transition: var(--standard-transition-all);
  width: 45%;
  .design-vvskupp & {
    border-left: var(--border-thin) var(--border);
  }
  @media (--mobile) {
    width: 100%;
  }
  .aa-dataset-groups,
  .aa-dataset-brands {
    padding: 10px 15px 15px 15px;
    border-bottom: var(--border-thin) var(--white);
    @media (--mobile) {
      border-bottom: none;
      border-top: var(--border-thin) var(--white);
      padding: 10px;
    }
    .design-lavpris &, .design-completvvs &, .design-vvskupp & {
      border-color: var(--primary-grey);
    }
    .aa-suggestion {
      display: inline-block;
      margin: 0 5px 5px 0;
      text-align: center;
      border-color: var(--primary-background-color);
      .design-vvskupp & {
        border-color: var(--border-brand-specific);
      }
      &:hover {
        border-color: var(--brand-color);
      }

      @media (--mobile) {
        margin: 0 3px 3px 0;
      }
      > a {
        background-color: var(--white);
        padding: 8px 20px;
        border-radius: var(--rounded-corners);
        transition: var(--standard-transition-all);
        font-size: 0.9em;
        color: var(--standard-text-color);
        .design-lampeguru & {
          font-size: 0.8em;
        }
        .design-greenline & {
          font-size: 0.85em;
        }
        .design-lavpris & {
          font-size: 1em;
        }
        .design-vvskupp & {
          background-color: var(--secondary-background-color);
        }
        &:hover {
          border-color: var(--primary-color);
        }
        &:focus {
          .design-billigvvs &, .design-greenline &, .design-lampeguru & {
            color: var(--standard-text-color);
        }
      }
        @media (--tablet-sm) {
          padding: 6px 15px;
          font-size: 0.85em;
        }
        @media (--mobile) {
          font-size: 1em;
          padding: 5px 15px;
        }
      }
      span {
        small {
          display: none;
        }
      }
    }
  }

  .aa-dataset-groups,
  .aa-dataset-brands {
    .js-show-all {
      margin-left: 5px;
    }
  }
  /* Show/hide all groups/brands */
  .aa-dataset-groups,
  .aa-dataset-brands {
    a {
      text-decoration: none;
      white-space: nowrap;
    }
    .aa-suggestion-more {
      display: none;
    }
  }
  .aa-dataset-inspiration {
    font-size: 13px;
    padding: 10px 15px 19px 15px;
    line-height: normal;
    @media (--mobile) {
      padding: 10px;
    }
    .aa-suggestion {
      border: none;
    }
    .aa-suggestion > a {
      padding: 0;
      background-color: $gColorFont3;
      border-radius: 14px;
      transition: all 0.2s linear;
      border: 1px solid $color2;
      overflow: hidden;
      &:hover {
        border-color: $color1;
        .algolia-autocomplete__description__read-more {
          svg {
            fill: $color5;
            background-color: $color1;
          }
        }
      }
      @media (--mobile) {
        flex-direction: column;
      }
      .algolia-autocomplete__image {
        width: 50%;
        padding: 0;
        height: auto;
        max-height: 197px;
        overflow: hidden;
        margin: 0;
        border-radius: 4px 0 0 4px;
        img {
          max-width: none;
          @media (--mobile) {
            width: 100%;
          }
        }
        @media (--mobile) {
          width: 100%;
          border-radius: 4px 4px 0 0;
          max-height: 120px;
        }
      }
      .algolia-autocomplete__description {
        font-size: 12px;
        padding: 15px;
        font-family: arial;
        min-height: 197px;
        width: 100%;
        justify-content: flex-start;
        h4 {
          max-width: 100%;
          margin-bottom: 5px;
          font-size: 15px;
          line-height: 22px;
          text-transform: uppercase;
          letter-spacing: 2px;
          word-spacing: 2px;
          max-height: 66px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          @media (--mobile) {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
        }
        @media (--mobile) {
          padding: 15px;
          overflow: visible;
          white-space: normal;
          min-height: auto;
          max-height: 222px;
        }
        &__text {
          max-width: 100%;
          max-height: 74px;
          line-height: 1.5;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          @media (--mobile) {
            height: 100%;
            display: none;
          }
        }
      }
      .algolia-autocomplete__description__read-more {
        margin-top: 10px;
        color: $color1;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 13px;
        width: auto;
        svg {
          fill: $color1;
          display: inline-block;
          background-color: aliceblue;
          padding: 7px;
          height: 21px;
          width: 21px;
          margin: 0 0 0 7px;
          border-radius: 100%;
        }
        @media (--mobile) {
          position: initial;
        }
      }
    }
  }
}
.aa-dataset-products {
  flex-grow: 1;
  width: auto;
  padding: 10px 15px;
  width: 55%;

  @media (--mobile) {
    padding: 5px 10px;
    width: 100%;
  }
}
.aa-suggestion > a {
  display: flex;
  padding: 5px 15px 5px 5px;
  text-decoration: none !important;
  color: var(--standard-text-color) ;
  position: relative;
  @media (--mobile) {
    padding: 5px 10px 5px 5px;
  }
  &.algolia-autocomplete__precise-match {
    border: var(--border-thin) var(--secondary-color);
    position: relative;
    border-radius: var(--rounded-corners);
    height: 84px;
    margin: -1px;
    .design-lavpris & {
      border-color: var(--primary-color);
    }
    &:hover {
      border-color: var(--brand-color);
    }
    &:before {
      content: attr(data-title-precise-match);
      text-transform: var(--text-uppercase-on-splash-notification);
      position: absolute;
      top: -10px;
      left: 16px;
      background-color: var(--secondary-color);
      color: var(--color-on-secondary-color);
      padding: 1px 10px;
      font-family: var(--brand-font);
      font-size: 12px;
      border-radius: var(--rounded-corners-counter-sm);
      .design-lavpris & {
        background-color: var(--primary-color);
        color: var(--color-on-primary-color);
      }
      @media (--mobile) {
        left: 10px;
        font-size: 10px;
        padding: 0 7px;
      }
    }
    @media (--mobile) {
    border-radius: var(--rounded-corners-ms);
    }
  }
  .algolia-autocomplete__image {
    background-color: $gColorFont3;
    width: 72px;
    height: 72px;
    padding: 8px;
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    @media (--mobile) {
      width: 68px;
    height: 68px;
    padding: 6px;
    margin-right: 4px;
    }
  }
  .algolia-autocomplete__description {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 16px;
    font-family: var(--brand-font);
    line-height: 1.4;
    width: 100%;
    @media (--new-desktop-sm) {
      font-size: 15px;
    }
    @media (--new-tablet) {
      font-size: 15px;
    }
    @media (--new-mobile) {
      font-size: 14px;
    }
    span {
      width: 100%;
    }
  }
  .algolia-autocomplete__name {
    width: 100%;
    word-wrap: break-word;
    text-wrap: wrap;
    color: var(--standard-text-color) !important;
    font-weight: var(--title-font-weight);
    @include line-clamp(2);
  }
  .algolia-autocomplete__splash__tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .algolia-autocomplete__splash {
    position: static;
    width: auto;
    height: auto;
    font-size: 12px;
    text-transform: var(--text-uppercase-on-splash-notification);
    margin-right: 5px;
    & > * {
      margin: 6px 0 0 0;
      padding: 1px 8px;
      border-radius: var(--rounded-corners-counter-sm);
      line-height: normal;
      font-weight: var(--font-weight-on-splash-notification);
      @media (--mobile) {
        margin: 4px 0 0 0;
      }
    }
    @media (--mobile) {
      font-size: 10px;
      padding: 0;
    }
    .product-card__part-of-bundle__tag,
    .product-card__splash__tag,
    .product-card__volume-discount__tag,
    .splash__notification__discount {
      font-size: inherit;
      @media (--mobile) {
        font-size: 10px;
        padding: 1px 7px;
      }
    }
    .product-card__splash__tag {
      background-color: var(--primary-color);
      color: var(--color-on-primary-color);
      border: var(--border-thin) var(--primary-color);

      .design-billigvvs & {
        color: var(--brand-color);
        background-color: var(--white);
      }
    }
  }
  .algolia-autocomplete__price-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: fit-content;
    padding-left: 4px;
    gap: 12px;
    white-space: nowrap;

  }
  .algolia-autocomplete__price {
    font-family: var(--brand-font);
    color: var(--primary-color);
    font-size: 18px;
    text-align: right;
    flex-shrink: 0;
    margin-left: 15px;
    font-weight: var(--font-weight-price);
    .design-vvskupp &, .design-greenline & {
      color: var(--dark);
    }
    @media (--mobile-md) {
      font-size: 16px;
    }
  }
  .algolia-autocomplete__price--discount {
    color: var(--discount-color) !important;
  }
  .algolia-autocomplete__price--normal_price {
    color: var(--subdued-color);
    text-decoration: line-through;
    text-align: right;
    font-size: 12px;
    font-weight: var(--font-weight-price);
    font-family: var(--brand-font);
    flex-shrink: 0;
    margin-left: 15px;
    padding-bottom: 4px;
  }
  .algolia-autocomplete__freight-notice {
    color: var(--muted);
    font-size: 11px;
    flex-shrink: 0;
    @media (--mobile-sm) {
      font-size: 10px;
    }
  }
  .splash__percentage__svg {
    height: 30px;
    width: 30px;
    top: -5px;
    right: -5px;
  }
  .splash__percentage {
    font-size: 10px;
    top: 0;
    right: 0;
  }
  @media (--mobile) {
    .splash__percentage__svg {
      top: 25px;
      right: 5px;
    }
    .splash__percentage {
      font-size: 10px;
      top: 30px;
      right: 8px;
    }
  }
}
.aa-hint {
  color: var(--muted);
}

@media (--mobile) {
  .aa-datasets {
    flex-direction: column;
    font-size: 12px;
    .aa-suggestions > a {
      padding: 0;
    }
    .algolia-autocomplete__image {
      height: 50px;
      width: 50px;
      padding: 2px;
    }
    .algolia-autocomplete__description {
      overflow: hidden;
      font-size: 13px;
    }
    .algolia-autocomplete__price {
      font-size: 14px;
      line-height: initial;
    }
    .algolia-autocomplete__price-area {
      padding-bottom: 0;
    }
    .algolia-autocomplete__freight-notice {
      font-size: 11px;
      right: 10px;
      bottom: 1px;
    }
    .aa-dataset-groups,
    .aa-dataset-brands,
    .aa-dataset-inspiration {
      font-size: 12px;
      width: auto;
    }
    .aa-dataset-inspiration {
      border-top: var(--border-thin) var(--white);
      .design-lavpris &, .design-completvvs & {
        border-color: var(--primary-grey);
      }
    }
    .aa-dataset-products {
      width: auto;
    }
    .btn-sm {
      font-size: 0.85rem;
      padding: 8px 16px;
      @media (--mobile) {
        width: 100%;
        }
    }
  }
}
.aa-datasets {
  background-color: $gColorFont3;
  display: flex;
  position: relative;
  top: 3px;
  border-radius: var(--rounded-corners);
  box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.7);
  z-index: 803;
  transition: var(--standard-transition-all);
  overflow-x: hidden;
  // 100% - logo-header - search header - padding
  max-height: calc(100dvh - 52px - 52px - 10px);

  .header--mobile-scrolled & {
    // 100% - search header - padding
    max-height: calc(100dvh - 52px - 10px);
  }
  @media (--mobile-md) {
    border-radius: var(--rounded-corners-ms);
  }
}

.aa-suggestion {
  cursor: pointer;
  border-radius: var(--rounded-corners);
  border: var(--border-thin) var(--border);
  margin: 5px 0;
  &.aa-cursor,
  &:hover {
    border-color: var(--brand-color);
    transition: var(--standard-transition-all);
    .algolia-autocomplete__precise-match {
      border-color: var(--brand-color);
      &:before {
        background-color: var(--brand-color);
        color: var(--color-on-brand-color);
        border-color: var(--brand-color);
      }
    }
  }
  em {
    font-weight: 400;
    font-style: normal;
    color: $color1;
  }
  @media (--mobile) {
  border-radius: var(--rounded-corners-ms);
  }
}

.aa-datasets {
  .heading--4,
  .heading--3 {
    text-align: center;
    margin-bottom: 10px;
  }
  footer {
    padding: 5px 0 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;

  }
}

.aa-dropdown-menu__overlay {
  top: 71px;
  z-index: 100;
  transition: all 0.1s linear;
  @media (--mobile) {
    top: 73px;
    .header--mobile-scrolled & {
      top: 112px;
    }
  }
}
