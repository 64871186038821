@import "~styles/utils/media-queries";

.header {
  font-size: 18px;
  margin: 15px 15px 0;
  font-weight: var(--font-weight-bold);
  line-height: 1.75rem;

  @media (--mobile) {
    font-size: 17px;
    line-height: 1.55rem;
  }
}

.fullscreen-header {
  padding: 30px 40px 5px;

  @media (--mobile) {
    padding-top: 15px;
  }
}

.subtitle {
  color: var(--muted);
  margin: 0 15px 15px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
}

.size-fullscreen {
  .header {
    @media (--mobile) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .subtitle {
    padding-left: 15px;
    padding-right: 15px;
  }
}
