.landingpage__topbanner,
.landingpage__videotopbanner {
  background-color: $color1;
  background-image: none;
  a:hover {
    text-decoration: none;
  }
  &__dot {
    &--active {
      button {
        background-color: $color4;
        border-color: $color4;
      }
    }
  }
  &__nav {
    border-radius: var(--rounded-corners);
    opacity: 1;
    background-color: rgba(26, 26, 26, 0.5);
    svg {
      fill: rgba(255, 255, 255, 0.7);
    }
    &:hover {
      background-color: rgba(26, 26, 26, 0.7);
      svg {
        fill: $color5;
      }
      box-shadow: none;
    }
      padding: 25px 27px 25px 23px;
      left: 0;
      svg {
        height: 20px;
        width: 20px;
      }
      &--next {
        padding: 25px 23px 25px 27px;
        left: auto;
        right: 0;
      }
    
    @media (--tablet-lg) {
      padding: 20px 22px 20px 18px;
      left: 0;
      svg {
        height: 15px;
        width: 15px;
      }
      &--next {
        padding: 20px 18px 20px 22px;
        left: auto;
        right: 0;
      }
    }
    @media (--tablet-sm) {
      padding: 18px 20px 18px 16px;
      left: 0;
      svg {
        height: 12px;
        width: 12px;
      }
      &--next {
        padding: 18px 16px 18px 20px;
        left: auto;
        right: 0;
      }
    }
  }

  &__caption {
    h1,
    .heading--1,
    .heading,
    p {
      width: auto;
      text-transform: none;
      max-height: none;
      background-color: rgba(255, 212, 47, 0.9);
      padding: 5px 20px;
      color: $color3;
      @media (--mobile) {
        padding: 3px 10px;
        max-height: auto !important;
      }
    }
    &--half-width {
      h1,
      p,
      .heading {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  &__item {
    width: auto;
    min-width: 20%;
    height: 100%;
    transform: scale(1);
    transform: rotate(0deg);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    img {
      max-height: 100%;
    }
    @media (--tablet-lg) {
      padding: 0 15px;
    }
    @media (--tablet-sm) {
      padding: 0 10px;
    }
    @media only screen and (max-width: 960px) and (min-width: 711px) {
      padding: 0 5px;
    }
    @media only screen and (max-width: 710px) {
      height: 50% !important;
      width: 50% !important;
      padding: 5px;
      position: initial;
      img {
        align-items: center !important;
        justify-content: center !important;
        transform-origin: center center !important;
      }
    }
    &-5 {
      @media only screen and (max-width: 1240px) {
        display: none;
      }
    }
    &__wrapper {
      display: flex;
      flex-wrap: nowrap;
      height: 355px;
      justify-content: center;
      width: 100%;
      @media (--tablet-lg) {
        height: 315px;
      }
      @media (--tablet-sm) {
        height: 257px;
      }
      @media only screen and (max-width: 960px) and (min-width: 711px) {
        height: 241px;
      }
      @media only screen and (max-width: 710px) {
        flex-wrap: wrap;
        height: calc(100% - 171px);
        position: absolute;
        left: 0;
        bottom: 0;
      }
      @media (--mobile-sm) {
        height: calc(100% - 139px);
      }
    }
  }
}
