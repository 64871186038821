@import "~styles/utils/media-queries";

.topbanners {
  & .arrow {
    margin-left: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    border-radius: var(--rounded-corners-circle-or-square);
    background-color: var(--brand-color);
    flex-shrink: 1;
    .design-greenline & {
      border: var(--border-thin) var(--color-on-brand-color);
      background-color: transparent;
    }
    .design-vvskupp & {
      border: var(--border-thin) var(--color-on-highligted-background-color);
      background-color: transparent;
    }
    .design-completvvs &, .design-lampeguru & {
      background-color: transparent;
      border: none;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (--tablet-sm) {
      height: 20px;
      width: 20px;
    }
    @media (--mobile) {
      display: none;
    }

    svg {
      height: 11px;
      width: 11px;
      display: block;
      fill: var(--color-on-brand-color);
      .design-completvvs &, .design-lampeguru & {
        height: 15px;
        width: 15px;
      }
      .design-vvskupp & {
       fill: var(--color-on-highligted-background-color);
      }
      @media (--tablet-sm) {
        height: 9px;
        width: 9px;
        .design-completvvs &, .design-lampeguru & {
          height: 12px;
          width: 12px;
        }
      }
      @media (--mobile) {
        height: 7px;
        width: 7px;
        .design-completvvs &, .design-lampeguru & {
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  & .close-topbanner {
    position: absolute;
    right: 15px;
    top: 0;
    display: none !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media (--tablet-lg) {
      right: 35px;
    }
    @media (--tablet-sm) {
      right: 25px;
    }
    @media (--mobile) {
      right: 13px;
      top: -4px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: var(--standard-transition-all);
      cursor: pointer;
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
    svg {
      fill: var(--color-on-primary-color);
      height: 12px;
      width: 12px;
      opacity: 0.6;
      @media (--tablet-sm) {
        height: 10px;
        width: 10px;
      }
      @media (--mobile) {
        height: 9px;
        width: 9px;
      }
    }
  }
  &--banner {
    z-index: 2;
    overflow: hidden;
    height: auto;
    position: relative;
    font-family: var(--brand-font);
    text-align: center;
    display: block;
    text-decoration: none;
    font-size: 18px;
    font-weight: var(--brand-font-weight);
    color: var(--color-on-primary-color);
    padding: 8px 0;
    background-color: var(--offset-of-brand-color-darker);
    text-transform: var(--title-uppercase);
    .design-greenline &, .design-completvvs & {
      background-color: var(--primary-color);
    }
    .design-lavpris & {
      font-family: var(--button-font);
      background-color: var(--attention-color);
      font-weight: 400;
    }
    .design-lampeguru &,
    .design-vvskupp & {
      background-color: var(--secondary-color);
    }
    .design-vvskupp & {
      background-color: var(--highligted-background-color);
      color: var(--color-on-highligted-background-color);
    }
    @media (--tablet-sm) {
      padding: 6px 0;
    }

    @media (--mobile-md) {
      padding: 5px 0;
    }
    .outer-container {
      height: 100%;
    }
    & .hide--sm {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .showOn--sm {
      @media (--mobile) {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
    & p {
      margin: 0;
      font-size: 18px;
      line-height: normal;
      letter-spacing: var(--letter-spacing-sm);
      word-spacing: var(--letter-spacing-sm);
      text-transform: var(--title-uppercase);
      display: inline-flex;
      height: auto;
      font-weight: var(--brand-font-weight);
      color: var(--color-on-primary-color);
      .design-vvskupp & {
        color: var(--color-on-campaign-color);
      }

      .design-lavpris & {
        font-weight: 400;
        font-size: 26px;
      }
      @media (--tablet-lg) {
        font-size: 17px;

        .design-lavpris & {
          font-size: 24px;
        }
      }
      @media (--tablet-sm) {
        font-size: 16px;

        .design-lavpris & {
          font-size: 22px;
        }
      }
      @media (--tablet-xs) {
        font-size: 15px;

        .design-lavpris & {
          font-size: 20px;
        }
      }
      @media (--mobile) {
        font-size: 14px;
        max-width: calc(100% - 30px);
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;

        .design-lavpris & {
          font-size: 18px;
        }
      }
      @media (--mobile-md) {
        font-size: 13px;

        .design-lavpris & {
          font-size: 16px;
        }
      }

      @media (--mobile-sm) {
        font-size: 12px;

        .design-lavpris & {
          font-size: 14px;
        }
      }
    }

    &-warning {
      display: none;
      align-items: center;
      justify-content: center;
      svg {
        fill: $color5;
        margin-right: 10px;
        height: 20px;
        width: 20px;
        @media (--mobile) {
          height: 15px;
          width: 15px;
        }
      }
    }

    &-information_banner {
      background-color: var(--offset-of-brand-color-darker);
      display: block;
      height: auto;
      overflow: hidden;
      padding: 6px 16px;
      position: relative;
      text-align: center;
      .design-lampeguru & {
        background-color: var(--brand-color);
      }
      .design-lavpris & {
        background-color: var(--primary-color);
      }

      @media (--mobile-md) {
        padding: 4px 16px;
      }
      .arrow {
        display: none !important;
      }
      .close-topbanner {
        display: none;
      }
      p {
        color: var(--color-on-primary-color);
        font-family: var(--default-font);
        font-size: 14px !important;
        display: inline-flex;
        height: auto;
        line-height: normal;
        margin: 0;
        max-width: 100%;
        font-weight: var(--font-weight-bold);
        text-decoration: none;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;

        .design-lampeguru & {
          color: var(--color-on-brand-color);
          padding-top: 1px;
        }

        @media (--mobile-md) {
          font-size: 12px !important;
        }
      }
    }
  }

  &--small {
    padding: 5px 0px;
  }
}
a.topbanners--banner {
  color: var(--color-on-primary-color);
  transition: var(--standard-transition-all);
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: var(--color-on-primary-color);
    background-color: var(--hover-of-brand-color);
    transition: var(--standard-transition-all);
    text-decoration: none;
    .design-greenline & {
      .arrow {
        background-color: var(--color-on-brand-color);
        svg {
          fill: var(--hover-of-brand-color);
        }
      }
    }
    .design-lavpris & {
      background-color: color-mix(in srgb, var(--attention-color), #fff 10%);
    }
    .design-lampeguru & {
      background-color: color-mix(in srgb, var(--secondary-color), #fff 10%);
    }
    .design-vvskupp & {
      background-color: var(--hover-of-highligted-background-color);
    }
  }
  .arrow {
    @media (--tablet-and-above) {
      display: inline-flex;
      .design-lavpris & {
        display: none;
      }
    }
  }
}
a.topbanners--banner-information_banner {
  &:hover,
  &:focus {
    .design-lavpris & {
      background-color: color-mix(in srgb, var(--primary-color), #fff 30%);
    }
    .design-lampeguru & {
      background-color: var(--hover-of-brand-color);
    }
  }
}

.information_banner--critical {
  background: var(--error-color) !important;
  p {
    .design-lampeguru & {
      color: var(--white);
    }
  }
}

a.information_banner--critical {
  &:hover,
  &:focus {
    background-color: color-mix(in srgb, var(--error-color), 10%);
  }
}

.topbanner-wrapper {
  position: relative;
  overflow: hidden;
  user-select: none;
  &:hover {
    .landingpage__topbanner__nav {
      opacity: 1;
      left: 0;
    }
    .landingpage__topbanner__nav--next {
      opacity: 1;
      left: auto;
      right: 0;
    }
  }
  max-height: 550px;
  height: calc(100vw - 71vw);
  @media (--mobile-md) {
    height: 100vw;
    max-height: 500px;
  }

  .individual-topbanner {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.landingpage {
  &__topbanner,
  &__videotopbanner {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    border-radius: 0;
    position: relative;
    max-height: 550px;
    height: calc(100vw - 71vw);
    a {
      text-decoration: none;
    }

    @media (--mobile-md) {
      height: 100vw;
      max-height: 500px;
    }

    &__logo {
      position: absolute;
      bottom: 40px;
      right: 40px;
      z-index: 5;
      @media (--tablet-lg) {
        bottom: 35px;
        right: 35px;
      }
      @media (--tablet-sm) {
        bottom: 30px;
        right: 30px;
      }
      @media (--mobile) {
        bottom: 15px;
        right: 15px;
      }
      @media (--mobile-sm) {
        display: none;
      }

      .a-brand__image {
        max-height: 45px;
        max-width: 100px;
        @media (--tablet-sm) {
          max-height: 40px;
        max-width: 90px;
        }
        @media (--tablet-xs-and-less) {
          max-height: 35px;
        max-width: 80px;
        }
        @media (--mobile) {
          max-height: 30px;
          max-width: 70px;
        }
        @media (--mobile-md) {
          max-height: 25px;
          max-width: 60px;
        }
      }
    }
    &__dot {
      margin: 0 5px;
      padding: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      list-style: none;
      z-index: 10;
      width: 14px;
      @media (--tablet-lg) {
        margin: 0 4px;
        width: 12px;
      }
      @media (--tablet-sm-and-less) {
        margin: 0 3px;
        width: 10px;
      }
      @media (--mobile) {
        margin: 0 2px;
        width: 6px;
      }
      button {
        transition: var(--standard-transition-all);
        width: 14px;
        height: 14px;
        border-radius: var(--rounded-corners-circle-or-square);
        background-color: transparent;
        border: var(--border-thin) var(--white);
        cursor: pointer;
        @media (--tablet-lg) {
          width: 12px;
          height: 12px;
          padding: 0;
        }
        @media (--tablet-sm-and-less) {
          width: 10px;
          height: 10px;
          padding: 0;
        }
        @media (--mobile) {
          width: 6px;
          height: 6px;
          padding: 0;
        }

      }
      &--active {
        button {
          background-color: var(--white);
          border-color: var(--white);
        }
      }
      &__wrapper {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        text-align: center;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        overflow: hidden;
        @media (--tablet-lg) {
          bottom: 15px;
        }
        @media (--tablet-sm) {
          bottom: 15px;
        }
        @media (--tablet-xs-and-less) {
          bottom: 10px;

        }
        @media (--mobile) {
          bottom: 10px;

        }
        @media (--mobile-md) {
          align-items: flex-end;
          .design-billigvvs & {
            align-items: flex-start;
          }

        }
      }
    }
    &__number {
      color: $color5;
      font-size: 12px;
      font-family: $fontFamilyOswald;
      padding-left: 40px;
      justify-content: flex-start;
      @media (--tablet-sm) {
        padding-left: 30px;
      }
      @media (--mobile) {
        padding: 0;
        font-size: 10px;
        justify-content: center;
        align-items: flex-start;
        height: 35px;
      }
    }
    &__slider {
      overflow: hidden;
    }
    &__nav {
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -86px;
      background-color: hsla(0, 0%, 100%, 0.7);
      padding: 30px 20px 30px 8px;
      transition: var(--standard-transition-all);
      justify-content: center;
      z-index: 2;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        height: 20px;
        width: 20px;
        fill: rgba(68, 68, 68, 0.8);
        transform: rotate(90deg);
        transition: var(--standard-transition-all);
      }
      &--next {
        right: -39px;
        left: auto;
        padding: 30px 8px 30px 20px;
        svg {
          height: 20px;
          width: 20px;
          fill: rgba(68, 68, 68, 0.8);
          transition: var(--standard-transition-all);
          transform: rotate(270deg);
        }
      }
      &:hover {
        background-color: hsla(0, 0%, 100%, 0.9);
        box-shadow: 0 0 0 10px hsla(0, 0%, 100%, 0.4);
        @media (--tablet) {
          box-shadow: 0 0 0 6px hsla(0, 0%, 100%, 0.4);
        }
        @media (--mobile) {
          box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.4);
        }
        svg {
          fill: $gColorFont1;
        }
      }
      @media (--desktop) {
        padding: 60px 40px 60px 20px;
        border-radius: 0 100px 100px 0;
        svg {
          height: 25px;
          width: 25px;
        }
        &--next {
          border-radius: 100px 0 0 100px;
          padding: 60px 20px 60px 40px;
        }
      }
      @media (--tablet-lg) {
        padding: 50px 40px 50px 28px;
        border-radius: 0 100px 100px 0;
        svg {
          height: 20px;
          width: 20px;
        }
        &--next {
          border-radius: 100px 0 0 100px;
          padding: 50px 28px 50px 40px;
        }
      }
      @media (--tablet-sm-and-less) {
        padding: 40px 30px 40px 18px;
        border-radius: 0 100px 100px 0;
        svg {
          height: 20px;
          width: 20px;
        }
        &--next {
          border-radius: 100px 0 0 100px;
          padding: 40px 18px 40px 30px;

        }
      }
      @media (--tablet-xs-and-less) {
        padding: 30px 20px 30px 10px;
        border-radius: 0 40px 40px 0;
        svg {
          height: 15px;
          width: 15px;
        }
        &--next {
          padding: 30px 10px 30px 20px;
          border-radius: 40px 0 0 40px;
        }
      }
      @media (--mobile) {
        display: none;
      }
    }
    &__splash {
      &--pct {
        top: 9%;
        right: 3%;
        height: 25%;
        width: 25%;
        text-align: right;
        .shop-lavprisvvs-dk & {
          height: 30%;
          width: 30%;
        }
        @media (--tablet-sm-and-less) {
          height: 23%;
          width: 23%;
          right: 30px;
          top: 30px;
          .shop-lavprisvvs-dk & {
            height: 28%;
            width: 28%;
          }
        }
        @media (--tablet-xs) {
          right: 20px;
          top: 20px;;
        }
        @media (--mobile) {
          right: 15px;
          top: 15px;
        }

        @media (--mobile-md) {
          height: 18%;
          width: 18%;
          .shop-lavprisvvs-dk & {
            height: 23%;
            width: 23%;
          }
        }

        @media (--mobile-sm) {
          height: 16%;
          width: 16%;
          .shop-lavprisvvs-dk & {
            height: 21%;
            width: 21%;
          }
        }
      }
      &--text {
        right: 3%;
        top: 9%;
        position: absolute;

        @media (--tablet-sm) {
          right: 30px;
          top: 30px;
        }
        @media (--tablet-xs) {
          right: 20px;
          top: 20px;;
        }
        @media (--mobile) {
          right: 15px;
          top: 15px;
        }
      }
    }
    &__overlay {
      background-color: rgba(0, 0, 0, 0);
      width: 100%;
      height: 100%;
      display: flex;
      @media (--mobile-md) {
        flex-direction: column-reverse;
      }
    }
    &__caption {
      align-self: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 30px 0;
      margin: 0 auto;
      background-color: transparent;
      z-index: 1;
      max-width: 1435px;
      @media (--desktop-lg) {
        max-width: 1725px;
      }
      .design-completvvs & {
        text-align: left;
        align-self: flex-start;
      }
      @media (--tablet-lg) {
        padding-left: 100px;
        padding-right: 100px;
      }
      @media (--tablet-sm-and-less) {
        padding-left: 80px;
        padding-right: 80px;
      }
      @media (--tablet-xs-and-less) {
        padding-left: 30px;
        padding-right: 30px;
      }
      @media (--mobile-md) {
        padding: 25px 15px;
        justify-content: flex-end;
        .design-vvskupp &, .design-lavpris & {
          justify-content: center;
        }
      }

      &--half-width {
        width: 50%;
        padding: 30px 4.1%;


        .design-lavpris &, .design-completvvs & {
          text-align: center;
          align-items: center;
        }
        .splash {
          .design-completvvs &, .design-lampeguru &, .design-greenline &  {
            align-self: center;
          }
        }

        @media (--tablet-lg) {
          padding-left: 40px;
          padding-right: 40px;

        }

        @media (--tablet-sm) {
          padding: 20px 30px;
        }
        @media (--mobile) {
          padding: 15px 15px 25px;
        }

        @media (--mobile-md) {
          height: fit-content;
          flex-shrink: 0;
          width: 100%;
        }
      }

      h1,
      .heading--1 {
        color: $gColorFont3;
        margin: 0 auto 5px;
        font-size: 45px;
        line-height: 55px;
        letter-spacing: var(--letter-spacing);
        word-spacing: var(--word-spacing);
        text-transform: var(--title-uppercase);
        @include line-clamp(3);
        width: 100%;
        z-index: 3;
        @media (--desktop-lg) {
          font-size: 50px;
          line-height: 60px;;
        }
        @media (--tablet-lg) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (--tablet-sm) {
          font-size: 35px;
          line-height: 43px;
        }
        @media (--tablet-xs-and-less) {
          font-size: 30px;
          line-height: 38px;
          @include line-clamp(2);
        }
        @media (--mobile) {
          font-size: 25px;
          line-height: 31px;

        }

        @media (--mobile-md) {
          font-size: 23px;
          line-height: 28px;
        }

        @media (--mobile-sm) {
          font-size: 21px;
          line-height: 25px;
        }
      }
      p {
        z-index: 2;
        color: $gColorFont3;
        font-size: 22px;
        line-height: 32px;
        margin: 5px auto 20px auto;
        @include line-clamp(2);
        @media (--tablet-lg) {
          font-size: 20px;
          line-height: 30px;
        }
        @media (--tablet-sm) {
          font-size: 18px;
          line-height: 26px;
          margin: 0 auto 15px;
        }
        @media (--tablet-xs) {
          font-size: 16px;
          line-height: 24px;
          margin: 0 auto 10px auto;
        }
        @media (--mobile) {
          font-size: 14px;
          margin: 0 auto 5px auto;
          line-height: 20px;
        }

      }
      &--dark {
        h1,
        .heading--1,
        p {
          color: $gColorFont1;
        }
      }
      &--center {
        h1,
        .heading--1,
        p {
          text-align: center !important;
        }
      }
    }
    &__half-image {
      width: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0;
      border-radius: 0;
      position: relative;
      @media (--mobile-md) {
        width: auto;
        height: 75%;
      }

    }
    &__item {
      display: none;
    }
    &__button {
      margin: 0 auto;
      z-index: 2;
      &--center {
        text-align: center !important;
        align-self: center;
      }
      .btn {
        .design-lavpris & {
          font-family: var(--button-font);
          font-size: calc(var(--button-font-size)* 1.4);
        }

        @media (--mobile) {
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 5px;
          min-height: calc(var(--height-input-lg) - 8px);
          font-size: calc(var(--button-font-size) - 2px);
        }
      }
    }
    &__campaign {
      &__disclaimer {
        bottom: 20px;
        font-size: 12px;
        z-index: 1;
        @media (--mobile) {
          left: 15px;
          width: calc(100% - 30px);
          position: absolute;
        }
        &.light {
          color: var(--white);
        }
        &.dark {
          color: var(--dark);
        }
      }
      &__counter {
        width: 100%;
        text-align: center;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .counter-header {
          color: var(--white);
          font-family: var(--brand-font);
          font-size: 1rem;
          margin: 0 0 30px 0;
          text-transform: uppercase;
          &.light {
            color: var(--white);
          }
          &.dark {
            color: var(--dark);
          }
          @media (--mobile) {
            margin: 0 0 10px 0;
          }
          @media (--tablet-lg) {
            margin: 0 0 15px 0;
          }
          @media (--tablet-sm) {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}
.landingpage {
  .landingpage__topbanner__caption p {
    @media only screen and (max-width: 500px) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      margin: 0;
    }
  }
}

.topbanners {
  &__bg_1 {
    background-color: var(--brand-color);

    .design-billigvvs &, .design-greenline & {
      @media (--tablet-and-above) {
        border-top: var(--border-thin) color-mix(in srgb, var(--brand-color), #000 10%);
      }
    }
    .design-completvvs & {
      background-color: var(--secondary-background-color);
    }
    .design-lampeguru & {
      background-color: var(--offset-of-brand-color);
    }
  }
  &__bg_2 {
    background-color: var(--secondary-color);
    .design-billigvvs & {
      @media (--tablet-and-above) {
        border-top: var(--border-thin) color-mix(in srgb, var(--secondary-color), #000 10%);
      }
    }
    .design-lampeguru & {
      background-color: color-mix(in srgb, var(--offset-of-brand-color), #000 9%);
    }
    .design-lavpris & {
      background-color: var(--primary-color);
      h1,
      p {
        color: var(--white) !important;
      }
    }

    .design-vvskupp & {
      background-color: var(--campaign-color);
      border-top: var(--border-thin) #c7a843;
      h1,
      p {
        color: var(--color-on-campaign-color) !important;
      }
      .btn-whiteBorder {
        border-color: var(--dark);
        color: var(--dark);
        &:hover {
          background-color: var(--dark);
          color: var(--white);
          outline: none;
        }
      }
    }
  }
  &__bg_3 {
    background-color: var(--offset-of-brand-color-darker);


    .design-billigvvs & {
      @media (--tablet-and-above) {
        border-top: var(--border-thin) color-mix(in srgb, var(--offset-of-brand-color-darker), #fff 10%);
      }
    }
  }
}

.topbanner-container {
  position: relative;
  overflow: hidden;
}
