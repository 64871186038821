
.js-product-card__image {
  display: flex;
  justify-content: center;
}

.product-card {
  &__imageinfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    width: 100%;
    transition: top var(--standard-transition);
    @media (--desktop-lg) {
      padding-right: 25px;
    }

    @media (--tablet-lg) {
      padding-right: 15px;
    }
    @media (--tablet-sm) {
      padding-right: 10px;
    }

    @media (--tablet-xs) {
      padding: 0;
    }
    @media (--mobile) {
      padding: 0;
    }
  }
  &__energy {
    &__label {
      position: relative;
    }
    &__wrapper {
      position: absolute;
      z-index: 10;
      bottom: 15px;
      right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .product-card__product-image__energy-label__triangle {
        top: initial;
      }
      .product-card__product-image__energy-label__wrapper {
        bottom: 0;
        left: 0;
        position: initial;
        @media (--tablet-and-above) {
          margin-top: 5px;
        }
        @media (--tablet-sm) {
          margin: 0;
        }
      }
      @media (--tablet-sm-and-less) {
        bottom: 10px;
        right: 10px;
      }
      @media (--tablet-xs-and-less) {
        left: 10px;
        right: auto;
        align-items: flex-start;
      }
      @media (--mobile) {
        bottom: 25px;
      }
    }
    &__climate-friendly {
      height: 81px;
      width: 60px;
      margin-bottom: -2px;

      @media (--tablet-sm) {
        width: 50px;
        height: 67px;
      }
      @media (--mobile) {
        display: none;
      }
      @media (--mobile-lg) {
        display: block;
        width: 45px;
        height: 60px;
        margin: 0;
      }

      &__sm {
        height: 40px;
        width: 40px;
        display: block;
        @media (--tablet-and-above) {
          display: none;
        }
        @media (--mobile-lg) {
          display: none;
        }
      }
      &__wrapper {
        text-decoration: none;
        transition: all 0.2 ease-in-out;
        margin-bottom: 10px;
        &:hover {
          transition: all 0.2 ease-in-out;
          .product-card__energy__climate-friendly__text span {
            color: $gColorFont1;
            text-decoration: underline;
            transition: all 0.2 ease-in-out;
          }
        }
        @media (--mobile) {
          margin-bottom: 5px;
        }
      }
      &__text {
        margin: 4px 0 0 0;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        transition: all 1s ease-in-out;
        span {
          color: var(--muted);
          transition: all 0.2 ease-in-out;
          text-decoration: underline;
        }

        span:hover {
          color: var(--standard-text-color);
          text-decoration: underline;
        }
        @media (--tablet-sm) {
          font-size: 10px;
          margin: 2px 0 0 0;
        }

        @media (--mobile) {
          display: none;
        }
      }
    }
  }

  &__thumbnail {
    user-select: none;
    width: 90px;
    height: 90px;
    border-radius: var(--rounded-corners);
    margin: 2px 0;
    background-color: var(--white);
    overflow: hidden;
    border: var(--border-thin) var(--border);
    transition: border-color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    &__image__wrapper {
      display: flex;
      position: absolute;
      width: 100%;
    }

    svg {
      position: absolute;
      height: 25px;
      width: 25px;
      fill: $color1;
      transition: all 0.2s ease-in-out;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      border-color: $color1;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    img {
      max-height: 90px;
      max-width: 90px;
      padding: 10%;
    }
    img.environment-image {
      padding: 0;
    }
    iframe {
      padding: 0;
    }
    &__active {
      box-shadow: 0 0 0 1px $color1;
      border-color: $color1;
    }
    &__arrow {
      fill: var(--muted);
      transition: var(--standard-transition-all);
      padding: 10px 8px 10px 0;
      cursor: pointer;
      z-index: 1;
      width: 100%;
      display: flex;
      justify-content: center;

      @media (--tablet) {
        padding-right: 5px;
      }
      &:hover {
        fill: $gColorFont1;
      }
      svg {
        transform: rotate(180deg);
        height: 15px;
        width: 15px;
      }
      &--left {
        svg {
          transform: rotate(0deg);
        }
      }
    }
    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      @media (--mobile) {
        display: none;
      }
    }
    &-overflow__wrapper {
      padding: 0 0 0 2px;
      flex-grow: 1;
      height: 100%;
      width: 107px;
      position: relative;
      overflow: hidden;
      @media (--desktop-lg) {
        width: 137px;
      }
      @media (--tablet) {
        width: 102px;
      }
    }
    &-list__wrapper {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      text-align: center;
      width: 100%;
      padding: 0;
    }
  }
  &__product-image {
    width: 600px;
    height: 600px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    @media (--desktop-lg) {
      width: 735px;
      height: 680px;
    }
    @media (--tablet-lg) {
      width: 575px;
      height: 575px;
    }
    @media (--tablet-sm) {
      width: 500px;
      height: 500px;
    }
    @media (--tablet-xs) {
      width: calc(100vw - 162px);
      height: 66vw;
    }

    @media (--mobile) {
      width: 100vw;
      height: 80vw;
      margin: 0;
    }

    @media (--mobile-xl) {
      height: 66vw;
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      user-select: none;
      padding: 0;
      &__wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        user-select: none;
      }
      .img-fallback {
        height: 100%;
        width: 100%;
      }
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        padding: 8% !important;
      }
      img.environment-image {
        padding: 0 !important;
        object-fit: cover;
      }
      iframe {
        width: 100%;
        height: 56.25%;
        padding: 0;
      }
    }

    &__award {
      height: 80px;
      width: 80px;
      position: absolute;
      top: 20px;
      left: 20px;
      @media only screen and (max-width: 1280px) {
        height: 60px;
        width: 60px;
        top: 15px;
        left: 15px;
      }
    }
    &__stats {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 260px;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      text-align: left;
      align-items: center;
      background-color: $colorBg1;
      border-radius: 14px;
      padding: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      line-height: 18px;
      z-index: 10;
      border-bottom: var(--border-thin) $gColorBorder1;
      transition: background-color 0.2s ease-in-out;

      @media only screen and (max-width: 1280px) {
        width: 230px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
      }
      svg {
        height: 20px;
        width: 20px;
        margin: 0 10px 0 3px;
        fill: $color1;
      }
      &__first {
        top: calc(50% - 55px);
        @media only screen and (max-width: 1280px) {
          top: calc(50% - 45px);
        }
      }
      &__third {
        top: calc(50% + 55px);
        @media only screen and (max-width: 1280px) {
          top: calc(50% + 45px);
        }
      }
    }
    &__iu {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(245, 245, 245, 0.7);
      border-radius: 100px;
      padding: 10px 25px 10px 25px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 10px;
      text-transform: uppercase;
      color: $gColorFont1;
      text-decoration: none;
      z-index: 9;
      white-space: nowrap;
      transition: all 0.2s ease-in-out;
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgba(245, 245, 245, 0.9);
        .product-card__read-more__arrow {
          background-color: $color1;
          svg {
            fill: $color5;
          }
        }
      }
      img {
        width: 230px;
        margin: 3px auto 7px auto;
      }
      .product-card__read-more__arrow {
        margin: 0 auto;
        height: 20px;
        width: 20px;
        padding: 5px;
        border: 1px solid $color1;
        background-color: transparent;
        svg {
          fill: $color1;
        }
      }
      &--sm {
        padding: 5px 5px 5px 15px;
        flex-direction: row;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }

        .product-card__read-more__arrow {
          margin-left: 10px;
        }
        // IE10+
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          min-width: 73%;
        }
        // IE10+ CSS end
        @media only screen and (max-width: 1280px) {
          left: 50%;
          top: 35%;
          transform: translate(-50%, -35%);
          white-space: wrap;
          flex-direction: column;
          justify-content: center;
          padding: 6px 20px;
          flex-wrap: wrap;
          .product-card__read-more__arrow {
            margin: 0 auto;
          }
        }

        @media (--mobile) {
          display: none;
        }
      }
    }
    &__splash-tag {
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-family: var(--brand-font);
      padding: 5px 15px;
      border-radius: var(--rounded-corners);
      text-transform: var(--text-uppercase-on-splash-notification);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: normal;
      font-size: 14px;
      width: fit-content;
      font-weight: var(--brand-font-weight);
      @media (--tablet-sm-and-less) {
        font-size: 12px;
        padding: 4px 10px;
        right: 15px;
        bottom: 15px;
      }
      @media (--mobile) {
        border-radius: var(--rounded-corners-sm);
        bottom: 75px;
      }
    }
    &__splash {
      &--text {
        right: 20px;
        top: 20px;
        position: absolute;
        padding: 3px 10px;
        @media (--desktop) {
          font-size: 22px;
        }
        @media (--tablet) {
          font-size: 20px;
        }
        @media (--tablet-sm-and-less) {
          top: 15px;
          right: 15px;
        }
      }
      &--pct {
        top: 20px;
        right: 20px;
        height: 15%;
        width: 15%;
        min-height: 50px;
        min-width: 50px;
        .shop-lavprisvvs-dk & {
          height: 20%;
          width: 20%;
        }
        @media (--tablet-sm-and-less) {
          top: 15px;
          right: 15px;
        }
      }
    }
    &__arrow {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      fill: var(--muted);
      transition: all 0.2s ease-in-out;
      padding: 60px 40px 60px 20px;
      border-radius: 0 100px 100px 0;
      cursor: pointer;
      z-index: 1;
      user-select: none;
      svg {
        height: 20px;
        width: 20px;
        transform: rotate(90deg);
        @media (--tablet-sm) {
          height: 15px;
          width: 15px;
        }
      }
      &:hover {
        fill: $gColorFont1;
      }
      @media (--mobile) {
        padding: 40px 30px 40px 10px;
      }
      &--next {
        border-radius: 100px 0 0 100px;
        padding: 60px 20px 60px 40px;
        left: initial;
        right: 0;
        svg {
          transform: rotate(270deg);
        }
        @media (--mobile) {
          padding: 40px 10px 40px 30px;
        }
      }
    }

    &__dot {
      margin: 0 5px;
      padding: 0;
      display: none;
      text-align: center;
      justify-content: center;
      align-items: center;
      list-style: none;
      font-size: 0;

      @media (--mobile) {
        height: 14px;
        width: 20px;
        padding: 0px 2px;
        margin: unset;
        display: flex;
      }
      &__video {
        border-radius: 100%;
        cursor: pointer;
      }
      button {
        transition: all 0.2s ease-in-out;
        width: 14px;
        height: 14px;
        border-radius: 100px;
        border: none;
        background-color: transparent;
        border: 1px solid #999;

        cursor: pointer;
        @media (--mobile) {
          width: 10px;
          height: 10px;
          padding: 2px;
        }
        &:hover {
          background-color: #999;
          opacity: 1;
        }
      }
      svg {
        transition: all 0.2s ease-in-out;
        height: 14px;
        width: 14px;
        fill: #999;
        &:hover {
          fill: $gColorFont1;
        }
      }
      &--active {
        button {
          background-color: $color1;
          border-color: $color1;

          &:hover {
            background-color: $color1;
            border-color: $color1;
          }
        }
        svg {
          fill: $color1;
        }
      }

      &__wrapper {
        position: absolute;
        bottom: 17px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        text-align: center;
        width: 100%;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        @media (--mobile) {
          bottom: 7px;
          width: calc(100% - 40px);
        }
      }
    }
    &__numbers {
      background-color: rgba(245, 245, 245, 0.8);
      color: $gColorFont1;
      font-family: var(--brand-font);
      padding: 2px 8px;
      border-radius: var(--rounded-corners);
      &__wrapper {
        height: 30px;
        font-size: 12px;
      }
    }
  }
  &__inspiration-image {
    margin-top: 40px;
    margin-left: 120px;
    @media only screen and (max-width: 1879px) and (min-width: 1516px) {
      margin-left: 100px;
    }
    @media only screen and (max-width: 1515px) {
      margin: 30px 0 0 0;
    }
    a {
      position: absolute;
      bottom: 20px;
      right: 20px;
      box-shadow: none;
      background-color: hsla(0, 0%, 100%, 0.7);
      border-radius: 50px;
      padding: 5px 5px 5px 15px;
      text-align: right;
      display: flex;
      flex-direction: row;
      max-width: 90%;
      min-width: 280px;
      font-size: 10px;
      text-transform: uppercase;
      color: #444;
      text-decoration: none;
      z-index: 9;
      &:hover {
        text-decoration: underline;
        background-color: hsla(0, 0%, 100%, 0.9);
      }
      .product-card__read-more__arrow {
        margin: 0 0 0 10px;
        height: 20px;
        width: 20px;
        padding: 6px;
        &:hover {
          background-color: $color1;
          svg {
            fill: $color5;
          }
        }
      }
    }
  }
}
