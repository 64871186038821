@import "~styles/utils/media-queries";
@import "~styles/utils/mixins";

@keyframes fade-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: var(--standard-faded-overlay);
  }
}

.overlay {
  animation: fade-in var(--standard-transition);
  padding: 30px;
  align-items: center;
  background-color: var(--standard-faded-overlay);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  gap: 10px;

  @media (--mobile) {
    padding: 8px 8px 35px;
  }
}

.overlay-container {
  --bg-color: var(--white);
  --fill-color: var(--muted);

  padding: 30px;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3000;
  gap: 10px;
  flex-direction: column;

  @media (--mobile) {
    padding: 8px 8px 35px;
  }
}

.mobile-close {
  cursor: pointer;
  flex-shrink: 0;
  fill: var(--fill-color);
  transition: var(--standard-transition-all);
  z-index: 100;
  align-items: center;
  background-color: var(--bg-color);
  border-radius: var(--rounded-corners-circle-or-square);
  box-shadow: var(--modal-shadow);
  display: flex;
  font-size: 20px;
  height: 60px;
  justify-content: center;
  width: 60px;

  svg {
    height: 20px;
    width: 20px;
  }

  @media (--tablet-and-above) {
    display: none;
  }
}

.desktop-close {
  cursor: pointer;
  fill: var(--fill-color);
  height: 14px;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: var(--standard-transition-all);
  width: 14px;
  z-index: 100;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  @media (--mobile) {
    display: none;
  }
}

.size-fullscreen .mobile-close {
  display: none !important;
}

.size-fullscreen .desktop-close {
  display: block !important;
}

.modal {
  @include custom-scrollbar;

  background-color: var(--bg-color);
  border-radius: var(--rounded-corners);
  box-shadow: var(--modal-shadow);
  max-width: 100%;
  position: relative;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.size-small .modal {
  width: 400px;
}

.size-fullscreen .modal {
  height: 100%;
  width: 100%;

  @media (--mobile) {
    border-radius: 0;
  }
}

.size-fullscreen {
  padding: 30px;

  @media (--mobile) {
    padding: 0;
  }

  @media (--tablet-sm) {
    padding: 20px;
  }
}

.size-default .modal {
  width: 930px;
}

.type-info {
  --bg-color: var(--info-bg-color);
  --fill-color: var(--info-color);
}

.type-error {
  --bg-color: var(--error-bg-color);
  --fill-color: var(--error-color);
}

.type-warning {
  --bg-color: var(--warning-bg-color);
  --fill-color: var(--warning-color);
}

.type-success {
  --bg-color: var(--success-bg-color);
  --fill-color: var(--success-color);
}
