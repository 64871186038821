@import "~styles/utils/media-queries";
@import "~styles/utils/mixins";

.product_group_list {
  @include custom-scrollbar;
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  overflow-x: auto;
  padding: 15px 0 0 0;
  overflow-y: hidden;
  @media (--tablet-and-less) {
    padding-left: 40px;
    padding-right: 40px;
    gap: 10px;
    flex-wrap: nowrap;
    padding-top: 10px;
  }

  @media (--tablet-sm) {
    padding-left: 30px;
    padding-right: 30px;

  }
  @media (--mobile) {
    padding-left: 15px;
    padding-right: 15px;
    gap: 5px;

  }

  &__max-height {
      max-height: 232px;
      @media (--tablet-lg) {
        max-height: 215px;
      }
  }

  &__box {
    height: auto;
    border-radius: var(--rounded-corners);
    text-decoration: none;
    overflow: hidden;
    position: relative;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: max-content;
    flex: 0 0 auto;
    transition: var(--standard-transition-all);
    max-width: 290px;
    border: var(--border-thin) var(--border);
    .design-billigvvs &, .design-greenline & {
      border-color: var(--white);
    }
    
    &__text {
      padding: 0px 8px;
      @media (--tablet-sm-and-less) {
        border-radius: var(--rounded-corners-sm);
        
      }
    }
    img {
      object-fit: contain;
    }

    &:focus,
    &:active {
      overflow: hidden;
    }
    &.environment {
      img {
        padding: 0;
        object-fit: cover;
      }
    }
    @media (--mobile) {
      border-radius: var(--rounded-corners-sm);
    }

    &:hover,
    &:active,
    &:focus {
      overflow: hidden;
      border-color: var(--primary-color);
      background-color: var(--white);
      svg {
        fill: var(--standard-text-color);
      }
 
    }

    &__label {
      color: var(--standard-text-color);
      padding: 0 10px;
      margin: 0;
      align-self: center;
      line-height: normal;
      hyphens: auto;
      text-decoration: none;
      font-weight: var(--font-weight-bold);
      font-size: 16px;
      @media (--new-tablet) {
        font-size: 15px;
    
      }
      @media (--new-mobile) {
        font-size: 14px;
        padding: 0 5px;
      }


    }
    &__image {
      height: 70px;
      width: 70px;
      border-radius: var(--rounded-corners);
      background-color: var(--white);
      padding: 2px;
      object-fit: cover;
      @media (--tablet-lg) {
        height: 65px;
        width: 65px;
      }
      @media (--tablet-sm-and-less) {
        height: 60px;
        width: 60px;
        border-radius: var(--rounded-corners-ms);
      }
      @media (--tablet-xs) {
        height: 55px;
        width: 55px;
      }
      @media (--mobile-xl) {
        height: 50px;
        width: 50px;
      }
      @media (--mobile-md) {
        height: 45px;
        width: 45px;
      }
      @media (--mobile-sm) {
        height: 40px;
        width: 40px;
      }
    }
    &__arrow {
      svg {
        fill: var(--muted);
        position: absolute;
        right: 8px;
        height: 13px;
        width: 13px;
        bottom: 8px;
        @media (--tablet-sm-and-less) {
          display: none;
        }
      }
    }
  }
  &__show_more {
    @media (--tablet-sm-and-less) {
      display: none;
    }
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      .product_group_list__show_more__text {
        color: color-mix(in srgb, var(--standard-text-color), black 10%);
        text-decoration: underline;
      }
    }
    &__text {
      text-transform: var(--minor-header-uppercase);
      font: var(--minor-header);
      margin: 0;
      color: var(--standard-text-color);
    }
    &__wrapper {
      position: absolute;
      visibility: hidden;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--primary-background-color);
    }

    &__icon {
      display: flex;
      align-items: center;
      height: 15px;
      width: 15px;
      margin-left: 15px;
      align-self: center;
      svg {
        stroke: var(--chevron-icon);
        height: 18px;
        width: 18px;
        @media (--mobile) {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}
