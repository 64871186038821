@import "~styles/utils/media-queries";

  /// This styling is used only for countdown-timer.html.
  /// be aware that is will change the styling for the coundowns across all the websites its being used.

.design-billigvvs {
  --countdown-color-surface: var(--color-brand-600);
  --countdown-color-surface-text: var(--color-text-white);
  --countdown-color-text: var(--color-brand-50);
  --countdown-color-disclaimer: var(--color-brand-300);
  --countdown-color-textsplash: var(--color-brand-700);
}

.design-lavpris {
  --countdown-color-surface: var(--color-primary-500);
  --countdown-color-surface-text: var(--color-text-white);
  --countdown-color-text: var(--color-grey-950);
  --countdown-color-disclaimer: var(--color-brand-700);
  --countdown-color-textsplash: var(--color-primary-500);
}
.design-completvvs {
  --countdown-color-surface: var(--color-brand-600);
  --countdown-color-surface-text: var(--color-text-white);
  --countdown-color-text: var(--color-brand-50);
  --countdown-color-disclaimer: var(--color-brand-200);
  --countdown-color-textsplash: var(--color-brand-700);
}
.design-greenline {
  --countdown-color-surface: var(--color-brand-600);
  --countdown-color-surface-text: var(--color-text-white);
  --countdown-color-text: var(--color-brand-50);
  --countdown-color-disclaimer: var(--color-secondary-100);
  --countdown-color-textsplash: var(--color-brand-700);
}
.design-lampeguru {
  --countdown-color-surface: var(--color-primary-500);
  --countdown-color-surface-text: var(--color-text-white);
  --countdown-color-text: var(--color-grey-950);
  --countdown-color-disclaimer: var(--color-brand-700);
  --countdown-color-textsplash: var(--color-primary-500);
}
.design-vvskupp {
  --countdown-color-surface: var(--color-brand-600);
  --countdown-color-surface-text: var(--color-text-white);
  --countdown-color-text: var(--color-brand-50);
  --countdown-color-disclaimer: var(--color-brand-300);
  --countdown-color-textsplash: var(--color-brand-700);
}

.countdown-interaction {
  background-color: var(--countdown-color-surface);
  border-radius: var(--rounded-corners);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  @media (--mobile-md) {
    flex-direction: column;
      }
  @media (--mobile-sm) {
    width: 100%;
    }
}

.countdown-button-container {
  padding: 0 16px 0 0;
  width: 100%;
  @media (--mobile-md) {
    padding: 0px 12px 12px 12px;
  }
}
.countdown-timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (--mobile-sm) {
    width: 100%;
    justify-content: space-between;

    }
}
.countdown-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  color: var(--white);
  gap: 4px;
  @media (--tablet) {
    width: 84px;
  height: 84px;
  }
  @media (--mobile-md) {
    width: 78px;
  height: 78px;
  }
  @media (--mobile-sm) {
    width: 100%;
  height: 72px;
  }
}
.countdown-numbers {
  color: var(--white);
  font-weight: var(--font-weight-bold);
  font-size: 2rem;
  @media (--tablet) {
    font-size: 1.8rem;
  }
  @media (--mobile) {
  font-size: 1.6rem;
  }
}
.countdown-text {
  color: var(--color-brand-50);
  font-size: 0.85rem;
  @media (--mobile) {
    font-size: 0.80rem;
  }
  @media (--mobile-sm) {
    font-size: 0.75rem;
  }
}
.countdown-ending-soon {
  background-color: var(--urgent-color);
}
.countdown-separator-line {
  width: 1px;
  border: var(--border-thin) var(--color-primary-700);
  height: 24px;
  display: block;
  &:last-of-type {
    display: none;
  }
}
.countdown-red-background-separator-line{
  height: 0;
}

.countdown-btn-campaign {
  @media (--mobile-md) {
    width: 100%;
  }
}
