@import "~styles/utils/mixins";

.product-card {
  &__maininfo {
    padding: 0 0 0 20px;
    flex-shrink: 1;
    width: 100%;
    @media (--desktop-lg) {
      padding: 0 0 0 30px;
    }
    @media (--tablet-lg) {
      padding: 0 0 0 15px;
    }
    @media (--tablet-sm) {
      padding: 0 0 0 10px;
    }
    @media (--tablet-xs) {
      padding: 15px 0 0 0;
    }
    @media (--mobile) {
      border-bottom: var(--border-thin) var(--primary-grey);
      padding: 0 15px;
      width: 100%;
    }
    &__box {
      background-color: var(--primary-background-color);
      border-radius: var(--rounded-corners);
      padding: 15px;
      margin: 15px 0;
      .design-lampeguru & {
        background-color: var(--offset-of-brand-color);
      }
      .design-lavpris &,
      .design-completvvs &,
      .design-vvskupp & {
        background-color: var(--secondary-background-color);
      }

      @media (--tablet-sm-and-less) {
        padding: 10px;
        margin: 10px 0;
      }
      @media (--mobile) {
      border-radius: var(--rounded-corners-sm);
      }
    }
    &__wrapper {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: 30px;
      @media (--tablet-lg) {
        padding-bottom: 20px;
      }
      @media (--tablet-sm) {
        padding-bottom: 20px;
      }
      @media (--tablet-xs) {
        flex-direction: column;
        padding-bottom: 30px;
      }

      @media (--mobile) {
        display: block;
        flex-wrap: wrap;
        padding: 0;
      }
    }
    &__top {
      @media only screen and (min-width: 1025px) {
        position: absolute;
        right: 15px;
        top: 5px;
      }
      @media only screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
      }
      .breadcrumb {
        display: none;
        @media (--mobile) {
          display: block;
          margin-left: -3px;
          padding-left: 0;
        }
      }
    }

    .drop-down {
      border: none;
      &:hover {
        border-color: $color1;
        .drop-down__see-more-header {
          background-color: $color1;
          border-color: var(--secondary-background-color);
          color: $color5;
        }
      }
      &__open {
        border-color: $gColorBorder1 !important;
        .drop-down__see-more-header {
          background-color: $color5 !important;
          border-color: $gColorBorder1 !important;
          color: $gColorFont1 !important;
        }
      }
      &__body__item {
        flex-wrap: nowrap;
        -webkit-line-clamp: 3;
        max-height: 60px;
        white-space: initial;
      }
      .product-card__variants__savings {
        font-size: 12px;
        padding: 3px 10px;
        @media (--mobile) {
          padding: 2px 10px;
        }
      }
      .label-name,
      .colon {
        @media only screen and (max-width: 599px) {
          display: none;
        }
      }
    }
  }
  &__recommendations {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 0 0;
    padding: 0;
    &__text {
      font-size: 14px;
      line-height: 1.5em;
      font-weight: var(--font-weight-bold);
      font-family: var(--default-font);
      @media (--mobile) {
        font-size: 12px;
      }
    }
    @media (--mobile) {
      margin: 10px 0 0 0;
    }
    &__tooltip {
      padding: 0 !important;
      @media (--mobile) {
        margin: 0 15px;
      }
      &-arrow {
        background-color: var(--primary-color) !important;
        .design-completvvs & {
          background-color: var(--secondary-background-color) !important;
        }
      }
    }
    &__tooltiptext {
      font-size: 14px;
      line-height: 20px;
      font-weight: var(--font-weight-bold);
      text-align: center;
      color: var(--standard-text-color);
    }
    &__seperator {
      margin: 0 !important;
    }
    .product-card__recommendations__arrow {
      width: 10px;
      margin: 5px 15px 5px 5px;
    }
    .product-card__recommendations__rating {
      margin-right: 10px;
      @media (--tablet-sm-and-less) {
        display: none;
      }
    }
    &__content {
      display: flex;
    }
    &__count {
      color: $gColorFont2;
      font-weight: 300;

      &:hover {
        text-decoration: underline;
      }

      &.no-raiting {
        text-decoration: none;
      }
    }

    &__star {
      fill: var(--brand-color);
      height: 25px;
      margin-right: 2px;
      width: 25px;
      .design-billigvvs & {
        fill: var(--secondary-color);
      }
      .icon-stroke {
        stroke-width: 0;
 
      }
      &:last-of-type {
        margin-right: 10px;
      }

      @media (--tablet-sm-and-less) {
        height: 20px;
        margin-right: 3px;
        width: 20px;
      }
      &__not-given {
        fill: var(--primary-grey);
      }
    }
  }

  &__offer-expiry {
    font-size: 12px;
    margin: 0 0 10px;
    padding: 0;
    text-transform: uppercase;
    @media (--mobile) {
      margin: 0 0 5px;
    }
  }
  &__savings {
    align-items: center;
    background-color: var(--campaign-color);
    border: var(--border-thin) var(--campaign-color);
    border-radius: var(--rounded-corners-sm);
    color: var(--color-on-campaign-color);
    display: flex;
    font-family: var(--brand-font);
    font-size: 14px;
    justify-content: flex-start;
    line-height: normal;
    margin: 0 10px 10px 0;
    padding: 3px 15px;
    text-transform: var(--text-uppercase-on-splash-notification);
    font-weight: var(--font-weight-on-splash-notification);

    .design-lampeguru & {
      color: var(--color-on-brand-color);
      border-right: none !important;
      border-top: none !important;
      border-bottom: none !important;
      border-color: var(--color-brand-600);
      border-radius: 0;
    }
    &__expiry {
      background-color: var(--discount-color);
      color: var(--white);
      border: var(--border-thin) var(--discount-color);
      .design-lampeguru & {
        background-color: var(--brand-color);
        color: var(--color-on-brand-color);
        border: var(--brand-color);
      }
    }
    @media (--tablet-and-less) {
      margin: 0 5px 5px 0;
    }
    @media (--mobile) {
      font-size: 12px;
      padding: 2px 10px;
    }
  }
  &__extraordinary-savings {
    align-items: center;
    background-color: var(--campaign-color);
    border-radius: var(--rounded-corners-sm);
    color: var(--color-on-campaign-color);
    display: flex;
    font-family: var(--brand-font);
    font-size: 14px;
    justify-content: center;
    line-height: normal;
    margin: 0 10px 10px 0;
    padding: 2px 2px 2px 15px;
    font-weight: var(--font-weight-on-splash-notification);
    text-transform: var(--text-uppercase-on-splash-notification);
    @media (--tablet-and-less) {
      margin: 0 5px 5px 0;
    }
    @media (--mobile) {
      font-size: 12px;
      padding: 2px 2px 2px 12px;
    }
    .product-card__savings {
      border: var(--border-thin) var(--white);
      margin: 0 0 0 10px;
      padding: 1px 10px 1px 12px;
      .design-vvskupp & {
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-radius: 0;
        border-left: var(--border-thin) #c7a843;
      }
      @media (--mobile) {
        margin: 0 0 0 6px;
        padding: 0 10px 0 12px;
      }
    }
  }
  &__price {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 10px;
    @media (--tablet-and-less) {
      margin: 0 0 5px;
    }
    &__wrapper {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
  &__freight-modal {
    color: var(--subdued-color);
    text-decoration: underline;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 12px;
    display: block;
    @media (--mobile) {
      font-size: 10px;
    }
    &:hover {
      color: var(--standard-text-color);
      text-decoration: underline;
    }
  }
  &__per_meter_price {
    font-size: 14px !important;
    margin: 0 20px 0 -20px !important;
  }
  &__current-price {
    color: var(--product-price-color) !important;
    font-weight: var(--font-weight-price);
    &.product-card__current-price--offer {
      color: var(--discount-color) !important;
    }
    font-family: var(--brand-font);
    font-size: 30px;
    line-height: 40px;
    margin: 8px 30px 0 0;
    @media (--tablet) {
      font-size: 28px;
      line-height: 38px;
    }
    @media (--mobile) {
      font-size: 24px;
      line-height: 34px;
      margin: 0 20px 0 0;
    }
  }
  &__before-price {
    color: var(--before-price);
    font-weight: var(--before-price-font-weight);
    font-family: var(--brand-font);
    margin: 10px 30px 0 0;
    text-decoration: line-through;
    @media (--tablet) {
      font-size: 14px;
    }
    @media (--mobile) {
      font-size: 14px;
      margin: 5px 20px 0 0;
    }
  }
  &__cable-cutting-fee {
    font-weight: var(--font-weight-bold);
    @media (--tablet-and-above) {
      margin-right: 40px;
    }
    @media (--mobile) {
      margin-right: 30px;
    }
    @media (--mobile-sm) {
      margin-right: 20px;
    }
  }

  &__variants {
    font-size: 12px;
    font-weight: 400;
    height: 61px;
    margin: 0 0 10px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    width: 100%;
    @media (--mobile) {
      height: 51px;
    }
    &__content {
      display: flex;
    }
    &__modal {
      @media (--mobile) {
        width: 100%;
      }
      @media (--mobile-lg) {
        width: auto;
      }
    }
    &__text {
      box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: center;

      @media (--mobile) {
        max-width: 100%;
      }
      &__top {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    &__name {
      @include line-clamp(1);
    }
    &__description {
      color: $gColorFont2;
    }
    &__delivery {
      color: var(--muted);
      float: right;
      font-size: 12px;
      position: relative;
      @media (--mobile) {
        display: none;
      }
      @media (--mobile-lg) {
        display: block;
      }
    }

    &__savings {
      align-items: center;
      background-color: var(--brand-color);
      border-radius: var(--rounded-corners-sm);
      color: var(--color-on-brand-color);
      display: flex;
      font-family: var(--brand-font);
      font-size: 14px;
      justify-content: flex-start;
      line-height: normal;
      margin-left: 10px;
      padding: 3px 12px;
      font-weight: var(--font-weight-on-splash-notification);
      text-transform: var(--text-uppercase-on-splash-notification);
      @media only screen and (max-width: 499px) {
        display: none;
      }
    }

    &__price {
      font-weight: var(--font-weight-price);
      margin: 0 0 0 10px;
      @media (--mobile) {
        flex-shrink: 0;
      }
    }
    &__others {
      height: auto;
    }

    &__images {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
    &__image-variant {
      align-items: center;
      background-color: $color5;
      border-radius: 8px;
      box-shadow: 0 0 0 1px $gColorBorder1;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      height: 60px;
      justify-content: center;
      margin: 10px 8px 0 2px;
      overflow: hidden;
      width: 60px;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0 0 2px $color1;
      }
      img {
        max-height: 100%;
      }
      &__active {
        box-shadow: 0 0 0 2px $color1;
        position: relative;
        @media (--mobile) {
          box-shadow: 0 0 0 2px $color1;
        }
        &:hover {
          box-shadow: 0 0 0 2px $color1;
          @media (--mobile) {
            box-shadow: 0 0 0 2px $color1;
          }
        }
        .product-card__variants__selected {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &__colors {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 4px 0;
      transition: all 0.2s ease-in-out;
      width: 100%;
      @media (--mobile) {
        margin: 2px 0 0;
      }
    }
    &__color {
      background-color: $gColorFont1;
      border: 3px solid $color5;
      border-radius: 100%;
      box-shadow: 0 0 0 1px $gColorBorder1;
      cursor: pointer;
      display: block;
      height: 46px;
      margin: 7px 5px 2px 2px;
      width: 46px;
      &:hover {
        box-shadow: 0 0 0 1px $color1;
      }
      &__active {
        box-shadow: 0 0 0 2px $color1;
        position: relative;
        &:hover {
          box-shadow: 0 0 0 2px $color1;
        }
      }
    }
    &__selected {
      align-items: center;
      background-color: var(--secondary-color);
      border-radius: 50px;
      display: flex;
      height: 20px;
      justify-content: center;
      position: absolute;
      right: -5px;
      top: -10px;
      width: 20px;
      svg {
        height: 10px;
        width: 10px;
        .icon-stroke {
          stroke: var(--color-on-brand-color);
          stroke-width: 2px;
          .design-lampeguru & {
            stroke: var(--color-on-primary-color);
          }
        }
      }
      &__name,
      &__description {
        font-size: 11px;
        margin: 10px 0 0 7px;
        @media (--mobile) {
          text-align: center;
        }
        span {
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }
  }

  &__not-for-sale {
    font-family: var(--brand-font);
    font-size: 22px;
    line-height: 32px;
    padding: 20px;
    text-align: center;
    width: 100%;
    @media (--mobile) {
      font-size: 16px;
      line-height: 26px;
      padding: 0;
    }
    &__wrapper {
      margin-bottom: 20px;
      @media (--mobile) {
        margin-bottom: 25px;
        padding: 0 15px;
      }
      @media (--mobile-md) {
        margin-top: 15px;
      }
    }
  }

  &__CTA {
    display: flex;
    position: relative;
    width: 100%;
    @media (--mobile) {
      width: 100%;
    }
    &__wrapper {
      display: flex;
    }

    &__add-to-basket {
      .icon-stroke {
        stroke: var(--color-on-cta-color);
      }
      align-items: center;
      background-color: var(--cta-color);
      border: none;
      border-radius: 100px;
      color: $color5;
      cursor: pointer;
      display: flex;
      font-family: var(--brand-font);
      font-size: 26px;
      height: 60px;
      justify-content: center;
      line-height: normal;
      position: relative;
      transition: all 0.2s ease-in-out;
      width: calc(100% - 110px);
      @media (--tablet-lg) {
        font-size: 24px;
      }
      @media (--tablet-sm) {
        font-size: 22px;
      }
      @media (--mobile) {
        font-size: 20px;
        height: 50px;
        line-height: normal;
        width: calc(100% - 105px);
      }
      @media (--mobile-sm) {
        font-size: 18px;
      }
      &--error {
        background-color: $gColorFont9;
        &:hover {
          background-color: $gColorFont10 !important;
        }
      }
      &:hover {
        background-color: var(--cta-hover-color);
        .icon-stroke {
          stroke: var(--color-on-cta-hover-color);
        }
      }
      span {
        align-items: center;
        display: flex;
        fill: white;
        height: 60px;
        justify-content: center;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
      svg.cart {
        height: 24px;
        position: absolute;
        right: 30px;
        top: 18px;
        width: 24px;
        @media (--mobile) {
          height: 20px;
          right: 20px;
          top: 20px;
          width: 20px;
        }
        @media (--mobile-sm) {
          display: none;
        }
      }
      svg.done {
        fill: none;
        height: 22px;
        stroke: $color5;
        stroke-dasharray: 380;
        stroke-width: 25px;
        width: 100%;
      }
      @media (--mobile-md) {
        width: calc(100% - 65px);
      }
    
    }
  }
  &__volume-discount__tag,
  &__part-of-bundle__tag {
    border: var(--border-thin) var(--primary-color);
    color: var(--primary-color);
    background-color: transparent;
    .design-vvskupp & {
      background-color: var(--campaign-color);
      color: var(--color-on-campaign-color);
      border-color: var(--campaign-color);
    }
  }
  &__volume-discount,
  &__splash,
  &__part-of-bundle {
    background-color: var(--primary-background-color);
    border-radius: var(--rounded-corners);
    margin: -10px 0 15px;
    padding: 15px;
    width: 100%;
    .design-lampeguru & {
      background-color: var(--offset-of-brand-color);
    }
    .design-lavpris &,
    .design-completvvs &,
    .design-vvskupp & {
      background-color: var(--secondary-background-color);
    }


    @media (--tablet-sm-and-less) {
      padding: 10px;
      margin: -5px 0 10px;
    }
    &__tag {
      align-items: center;
      font-weight: var(--font-weight-on-splash-notification);
      border-radius: var(--rounded-corners-sm);
      display: flex;
      font-family: var(--brand-font);
      justify-content: flex-start;
      line-height: normal;
      margin: 0 10px 10px 0;
      padding: 3px 15px;
      text-decoration: none;
      font-size: 14px;
      text-transform: var(--text-uppercase-on-splash-notification);
      @media (--tablet-and-less) {
        margin: 0 5px 5px 0;
      }
      @media (--mobile) {
        font-size: 12px;
        padding: 2px 10px;
      }
    }
    &__header {
      font: var(--minor-header);
      text-transform: var(--minor-header-uppercase);
      color: var(--standard-text-color);
      @media (--mobile) {
        font: var(--minor-header-mobile);
      }
    }
    &__offers {
      display: flex;
      flex-wrap: wrap;
    }
    &__offer-1,
    &__offer-2,
    &__offer-3,
    &__offer-4 {
      margin-top: 10px;
      width: 50%;
      @media only screen and (max-width: 1239px) {
        width: 100%;
      }
    }
    .custom-control-description {
      font-family: $fontFamilyOswald;
      font-size: 18px;
      margin: 0 0 0 8px;
      min-width: 100px;

      @media (--mobile) {
        font-size: 16px;
        min-width: 85px;
      }
      span {
        font-size: 16px;
        margin-left: 6px;

        @media (--mobile) {
          font-size: 14px;
        }
      }
    }
    .custom-radio {
      align-items: center;
      display: flex;
      padding: 0;
      top: 0;
    }

    .product-card {
      &__CTA {
        margin: 10px 0 0;
        padding: 0;
        width: 100%;
        &__add-to-basket {
          width: 100%;
        }
      }
    }
    .product-card__volume-discount__offer {
      margin: 10px 0 0;
      padding: 0 20px 0 0;
      width: 100%;
      @media only screen and (min-width: 599px) {
        min-width: 50%;
        width: max-content;
      }
      .product-card__savings {
        height: 25px;
        margin: -3px 0 0 10px;
        white-space: nowrap;
        @media (--mobile) {
          font-size: 12px;
          height: 24px;
          margin: 0 0 0 10px;
        }
      }
    }
  }
  &__additional-info {
    color: $color1;
    display: flex;
    display: inline-flex;
    font-size: 12px;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
    @media (--mobile) {
      justify-content: flex-start;
      margin-bottom: 15px;
    }
    a {
      color: $color1;
      text-decoration: underline;
      &:hover {
        color: $color9;
        text-decoration: underline;
        svg {
          fill: $color9;
          transform: rotate(90deg);
        }
      }
    }

    svg {
      fill: $color1;
      height: 7px;
      margin-bottom: 1px;
      margin-left: 7px;
      transform: rotate(45deg);
      width: 7px;
    }
  }
}
