@import "../../styles/mixins";

.splash {
  &__notification {
    background: transparent;
    color: var(--brand-color);
    border: var(--border-thin) var(--brand-color);
    line-height: normal;
    text-transform: var(--text-uppercase-on-splash-notification);

    .design-lavpris &,
    .design-vvskupp & {
      background: var(--brand-color);
      color: var(--color-on-brand-color);
      border: none;
    }

    .design-greenline & {
      background: var(--brand-color);
      color: var(--color-on-brand-color);
      border-color: var(--brand-color);
    }

    .design-lampeguru & {
      background: transparent;
      color: var(--dark);
    }

    &__discount {
      background: var(--campaign-color);
      border: var(--border-thin) var(--campaign-color);
      color: var(--color-on-campaign-color);
      border: var(--border-thin) var(--campaign-color);

      .design-lavpris & {
        border: none;
      }
    }

    &__urgency {
      background: var(--urgent-color);
      color: var(--white);
      border: none;
      .design-lampeguru & {
        background: var(--secondary-color);
        color: var(--color-on-secondary-color);
      }
    }
    &__green {
      background: var(--success-color);
      color: var(--white);
      border: none;
      background: var(--success-color);
      color: var(--white);
      border: none;
    }

    &__brand {
      background-color: var(--brand-color);
      color: var(--color-on-brand-color);
      border-color: var(--brand-color);
    }

    &__black {
      background: #000;
      color: var(--white);
      border: none;
      .design-lavpris & {
        background: var(--primary-color);
        color: var(--color-on-primary-color);
      }
    }
  }

  &__notification__productcard {
    border-radius: 4px;
    margin: 0;
    float: initial;
    padding: 6px 10px;
    display: inline-flex;
    @media (--mobile) {
      height: 27px;
      padding: 5px 10px;
    }
  }
}
