.landingpage {
  &__campaign-banner,
  &__campaign-banner.a {
    text-align: center;
    height: auto;
    font-family: var(--brand-font);
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    text-transform: var(--title-uppercase);
    line-height: 1.1em;
    padding: 10px 20px;
    text-decoration: none;
    transition: var(--standard-transition-all);
    letter-spacing: var(--letter-spacing-sm);
    word-spacing: var(--word-spacing-sm);
    background-color: var(--highligted-background-color);
    color: var(--color-on-highligted-background-color);
    font-weight: var(--font-weight-regular);
    .design-completvvs &, .design-greenline & {
      background-color: var(--brand-color);
    }
    @media (--tablet) {
      padding: 8px 20px;
    }
    @media (--tablet-sm) {
      font-size: 16px;
      line-height: 21px;
    }
    @media (--mobile) {
      font-size: 12px;
      text-align: left;
      line-height: 16px;
      padding: 5px 0;
    }
    @media (--mobile-xl) {
      font-size: 14px;
      line-height: 18px;
    }
    @media (--mobile-sm) {
      font-size: 11px;
      line-height: 15px;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:hover {
      text-decoration: none;
      transition: var(--standard-transition-all);
      background-color: var(--hover-of-highligted-background-color);
      color: var(--color-on-highligted-background-color);



      .design-greenline & {
        color: var(--color-on-cta-color);
        background-color: var(--secondary-color);

        .landingpage__campaign-banner__arrow {
          background-color: var(--white);

          svg {
            fill: var(--cta-hover-color);
          }
        }
      }
    }

    span {
      font-weight: var(--brand-font-weight);
      margin: 0 5px 0 0;

      @media (--mobile) {
        margin: 0 2px 0 0;
      }
    }

    &__info {
      display: flex !important;
      justify-content: space-between;
    }

    &__text {
      display: inline-block;
      text-align: center;
      @media (--mobile) {
        width: calc(100% - 30px);
      }
    }

    &__divider {
      background-color: $color11;
      width: 2px;
      display: inline-block;
      margin: 0 20px;
      padding: 5px 0;
      @media (--tablet) {
        padding: 3px 0;
      }
      @media (--mobile) {
        display: none;
      }
    }

    &__arrow {
      height: 23px;
      width: 23px;
      border-radius: var(--rounded-corners-circle-or-square);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 15px;
      background-color: color-mix(in srgb, var(--highligted-background-color), black 20%);

      .design-greenline & {
        background-color: transparent;
        border: var(--border-thin) var(--white);
      }

      .design-vvskupp & {
        background-color: transparent;
        border: var(--border-thin) var(--color-on-highligted-background-color);
      }

      .design-completvvs &, .design-lampeguru & {
        background-color: transparent;
        border: none;
        margin: 0 0 0 10px;
      }

      .design-lavpris & {
        display: none;
      }

      @media (--mobile) {
        display: none;
      }

      svg {
        height: 11px;
        width: 11px;
        transform: rotate(90deg);
        fill: var(--color-on-highligted-background-color);

        .design-completvvs &, .design-lampeguru & {
          height: 15px;
          width: 15px;
        }

        @media (--tablet) {
          height: 10px;
          width: 10px;
          .design-completvvs &, .design-lampeguru & {
            height: 12px;
            width: 12px;
          }
        }
        @media (--mobile) {
          height: 10px;
          width: 10px;
        }
      }
    }
  }
}
