@import "~styles/utils/media-queries";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--primary-color);
  fill: var(--primary-color);
  gap: 5px;
  text-transform: var(--minor-header-uppercase);
  font-size: 12px;
  text-decoration: none;
  position: relative;

  @media (--tablet-sm) {
    font-size: 11px;
  }

  // Larger click area
  margin: 0 -10px;
  padding: 0 10px;

  :global(.design-lampeguru) &,
  :global(.design-vvskupp) & {
    color: var(--white);
    fill: var(--white);
  }

  :global(.design-greenline) &,
  :global(.design-completvvs) & {
    color: var(--standard-text-color);
    stroke: var(--dark);
  }

  :global(.design-lampeguru) &,
  :global(.design-vvskupp) &,
  :global(.design-greenline) &,
  :global(.design-completvvs) & {
    font-size: 14px;
    font-family: var(--brand-font);

    @media (--tablet-sm) {
      font-size: 13px;
    }
  }
}

.container:hover {
  text-decoration: underline;
  color: var(--standard-text-color);

  :global(.design-billigvvs) & {
    color: var(--primary-color);
  }

  :global(.design-lampeguru) &,
  :global(.design-vvskupp) & {
    color: var(--color-on-primary-color);
  }
}

.icon {
  width: 24px;
  height: 24px;

  :global(.icon-stroke) {
    :global(.design-lampeguru) &,
    :global(.design-vvskupp) & {
      stroke: var(--white);
    }

    :global(.design-billigvvs) & {
      stroke: var(--brand-color);
    
  }
  }


}

.text {
  @media (--mobile) {
    display: none;
  }
}

.counter {
  position: absolute;
  top: 4px;
  z-index: 1;
  margin-left: 22px;
  right: auto;
  background-color: var(--amount-background-color);
  color: var(--white);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  border-radius: var(--rounded-corners-circle-or-square);

  @media (--mobile) {
    width: 18px;
    height: 18px;
  }
}
