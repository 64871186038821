@import "../../node_modules/bootstrap/scss/functions";
@import "../custom-bootstrap-variables";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../custom-bootstrap-grid";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";

@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/close";

// // Utility classes
@import "../../node_modules/bootstrap/scss/utilities";

// Re-reboot some styling
svg {
  vertical-align: baseline;
}
