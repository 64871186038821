@import "~styles/utils/media-queries";

:global(#js-header) {
  display: contents; // This makes the react root "transparent", which allows us to use sticky
}

.container {
  position: sticky;
  z-index: 60;
  top: 0;
  margin-bottom: var(--megamenu-height);

  @media (--mobile) or (--no-hover) {
    margin-bottom: 0;
  }
}

.wrapper {
  background-color: var(--white);

  :global(.design-lavpris) &,
  :global(.design-vvskupp) & {
    background-color: var(--brand-color);
  }

  :global(.design-lampeguru) & {
    background-color: var(--primary-color);
  }
}

.header {
  align-items: center;
  height: 71px;
  display: grid;
  grid-template: "mobile-menu logo search buttons" auto / 0fr 1fr 2fr minmax(max-content, 1fr);
  gap: 0 30px;

  @media (--mobile) {
    height: unset;
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 5px 15px;
    grid-template:
      "mobile-menu logo buttons" auto
      "search search search" auto / max-content 1fr max-content;

    :global(.design-completvvs) &,
    :global(.design-greenline) & {
      border-bottom: 1px solid var(--brand-color);
    }

    .sticky & {
      grid-template: "mobile-menu search buttons logo" auto / max-content 1fr max-content 0fr;
      margin-bottom: 57px; // Hack to avoid flickering when switching from sticky to non-sticky
    }
  }
}

.header-mobile-menu {
  grid-area: mobile-menu;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  height: 100%;

  @media (--tablet-lg-and-above) {
    gap: 6px;
    }

  // Larger touch area
  margin: 0 -10px;
  padding: 0 10px;

  > * {
    @media (--tablet-lg-and-above) {
      height: 1px;

      :global(.design-greenline) &, :global(.design-lavpirs) &, :global(.design-completvvs) & {
        height: 2px;
      }
      }

    width: 24px;
    height: 1.5px;
    background-color: var(--primary-color);

    :global(.design-vvskupp) & {
      background-color: var(--white);
    }

    :global(.design-greenline) & {
      background-color: var(--dark);
    }

    :global(.design-lampeguru) & {
      background-color: var(--color-on-primary-color);
      height: 1px;
    }
  }


}

.header-mobile-menu-wrapper {
  display: none;
  flex-direction: row;
  gap: var(--spacing-300);
  background-color: var(--primary-background-color-grey);
  border-radius: var(--rounded-corners-ms);
  height: 100%;
  max-height: 48px;
  align-items: center;
  padding-left: var(--spacing-300);
  padding-right: var(--spacing-300);
  border: var(--border-thin) var(--border);
  font-weight: var(--font-weight-bold);

  :global(.design-completvvs) & {
    background-color: transparent;
  }
    
  :global(.design-lavpris) & {
    background-color: transparent;
    border-color: var(--color-on-brand-color);
  }

  :global(.design-lampeguru) & {
    background-color: transparent;
    border-color: var(--color-on-primary-color);
    color: var(--color-on-primary-color);
  }

  @media (--tablet-and-above) {
  &:hover, &:active {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--color-on-primary-color);

    .header-mobile-menu {
      > * {
        background-color: var(--color-on-primary-color);
      }
    }

    :global(.design-lampeguru) & {
      background-color: var(--color-primary-600);
      border-color: var(--color-primary-900);
    }
    
  }
}

@media (--tablet-sm) {
  max-height: 40px;
  font-size: 0.9rem;
}

  @media (--mobile) or (--no-hover) {
    display: flex;
  }

  @media (--mobile) {
    background-color: transparent;
    border: none;

    span {
      display: none;
    }
  }
}

.header-logo {
  grid-area: logo;
  display: flex;
  width: fit-content;

  @media (--mobile) {
    width: 100%;
    justify-content: center;
  }
}

.header-logo img {
  height: 48px;
  width: auto;
  padding: 0;

  @media (--tablet-sm) {
    padding: 4px 0;
  }

  :global(.shop-billigvvs-dk) &,
  :global(.shop-billigvvs-no) & {
    padding: 7px 0 0;

    @media (--tablet-sm) {
      padding: 9px 0 2px;
    }

    @media (--mobile) {
      padding: 10px 0 4px;
    }
  }

  :global(.shop-vvsochbad-se) & {
    padding: 8px 0;

    @media (--tablet-sm) {
      padding: 10px 0;
    }

    @media (--mobile) {
      padding: 11px 0;
    }

    @media (--mobile-sm) {
      padding: 12px 0;
    }
  }

  :global(.design-greenline) & {
    @media (--tablet-and-above) {
      padding: 4px 0 0;
    }

    @media (--tablet-sm) {
      padding: 5px 0 2px;
    }
  }

  :global(.design-completvvs) & {
    padding: 3px 0;

    @media (--mobile) {
      padding: 8px 0 5px;
    }
  }

  :global(.design-vvskupp) & {
    @media (--mobile) {
      padding: 8px 0;
    }
  }

  :global(.shop-lampeguru-dk) &,
  :global(.shop-lampeguru-no) & {
    padding: 6px 0;

    @media (--tablet-sm) {
      padding: 8px 0;
    }

    @media (--mobile) {
      padding: 11px 0 10px;
    }
  }

  :global(.shop-ljusochlampor-se) & {
    padding: 7px 0;

    @media (--tablet-lg) {
      padding: 8px 0;
    }

    @media (--tablet-sm) {
      padding: 10px 0;
    }

    @media (--mobile) {
      padding: 11px 0 10px;
    }

    @media (--mobile-sm) {
      padding: 12px 0 11px;
    }
  }

  @media (--mobile) {
    justify-content: center;
    padding: 6px 0 5px;
    width: auto;
    margin: 0 40px;

    .sticky & {
      display: none;
    }
  }
}

.header-search {
  grid-area: search;
}

.header-buttons {
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  height: 100%;

  > * {
    height: 100%;
    flex-basis: 90px;
  }

  @media (--tablet-sm-and-less) {
    gap: 20px;
  }
}

.hide-mobile {
  @media (--mobile) {
    display: none;
  }
}
