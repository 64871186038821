.blockbanner {
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 2rem;
  line-height: 1;
  font-family: var(--brand-font);
  background-size: cover !important;
  background-position: 50% 50% !important;
  border-radius: var(--rounded-corners);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  height: 100%;
  overflow: hidden;
  @media (--desktop-short) {
    padding: 30px;
    br {
      display: none;
    }
  }
  @media (--mobile) {
    padding: 30px;
    display: flex;
    min-height: 300px;
    flex-direction: column;
    justify-content: center;
  }

  .blockbanner--text {
    display: none;
    font-family: var(--default-font);
    padding: 5px 0 15px 0;
    font-size: 18px !important;

    @media (--tablet) {
      font-size: 16px !important;
    }
    @media (--mobile) {
      font-size: 15px !important;
      padding: 5px 0 10px 0;
    }
  }
  svg.btn {
    width: 100px;
    height: 90px;
  }

  .btn {
    .design-lavpris & {
      font-family: var(--button-font);
      font-size: calc(var(--button-font-size)* 1.4);
    }
  }
  .heading--2 {
    margin: 0 0 15px 0;
    letter-spacing: var(--letter-spacing);
    text-transform: var(--title-uppercase);
    z-index: 2;
    line-height: normal;
    @include line-clamp(3);
    .design-lavpris & {
      @include line-clamp(2);
      background-color: rgba(255, 212, 47, 0.9);
      padding: 5px 10px;
      color: var(--standard-text-color);
    }
    @media (--tablet) {
      margin: 0 0 10px 0;
    }
    @media (--mobile) {
      margin: 0 0 10px 0;
      letter-spacing: var(--letter-spacing-sm);
    }
  }

  &__dark {
    .heading--1,
    .heading--2,
    .heading--3 {
      color: var(--dark);
    }
  }
  &__light {
    .heading--1,
    .heading--2,
    .heading--3 {
      color: var(--white);
      .design-lavpris & {
        color: var(--dark);
      }
    }
  }
  .cms-banner & {
    @media (--mobile) {
      margin: 7px 15px 8px 15px;
      border-radius: 14px;
      padding: 25px;
      overflow: hidden;
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__full-image {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    .design-lavpris &, .design-greenline & {
      padding-top: 50px;
    }
    @media (--tablet-lg) {
      .design-lavpris &, .design-greenline & {
        padding-top: 60px;
      }
    }
    @media (--tablet-sm-and-less) {
      padding: 20px;
      .design-lavpris &, .design-greenline & {
        padding-top: 40px;
      }
    }
    @media (--mobile) {
      padding: 15px;
      .design-lavpris &, .design-greenline & {
        padding-top: 50px;
      }
    }
    @media (--mobile-sm) {
      padding: 10px;
      .design-lavpris &, .design-greenline & {
        padding-top: 30px;
      }
    }
  }
  &__half-image {
    width: 50%;
    background-color: $gColorbg11;
    height: 100%;
    padding: 28px 18px 28px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    @media (--tablet-sm-and-less) {
      padding: 23px 13px 23px 23px;
    }
    @media only screen and (max-width: 700px) {
      width: 100%;
      height: 50%;
      top: 50%;
      padding: 15px 25px 25px 25px;
    }
    @media only screen and (max-width: 366px) {
      padding: 10px 20px 20px 20px;
    }
    .heading--2 {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 3px;
      word-spacing: 3px;
      /* autoprefixer: ignore next */
      overflow: hidden;
      -webkit-line-clamp: 7;
      text-overflow: ellipsis;
      max-height: 195px;
      -webkit-box-orient: vertical;
      display: -webkit-box;

      @media (--tablet) {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 3px;
        word-spacing: 3px;
        max-height: 182px;
      }
      @media only screen and (max-width: 960px) and (min-width: 701px) {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 2px;
        word-spacing: 2px;
        max-height: 182px;
      }
      @media only screen and (max-width: 700px) {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 2px;
        word-spacing: 2px;
        max-height: 75px;
        margin: 0 0 15px;
        -webkit-line-clamp: 3;
      }
      @media only screen and (max-width: 366px) {
        font-size: 14px;
        line-height: 22px;
        max-height: 66px;
        margin: 0 0 10px;
      }
    }
  }
  &__splash {
    &--text {
      right: 15px;
      top: 15px;
      position: absolute;
      font-size: 22px !important;
      @media (--tablet-lg) {
        font-size: 21px !important;
      }
      @media (--tablet-sm-and-less) {
        font-size: 20px !important;
        top: 10px;
        right: 10px;
      }
      @media (--tablet-xs) {
        font-size: 19px !important;
      }
      @media (--mobile) {
        font-size: 16px !important;
      }
      @media (--mobile-xl) {
        font-size: 18px !important;
      }
      @media (--mobile-sm) {
        font-size: 14px !important;
      }
    }
    &--pct {
      top: 15px;
      right: 15px;
      height: 18%;
      width: 18%;
      .shop-lavprisvvs-dk & {
        height: 23%;
        width: 23%;
      }
      @media (--tablet-sm-and-less) {
        top: 10px;
        right: 10px;
      }
      @media (--mobile) {
        svg {
          height: 100% !important;
          width: unset !important;
        }
      }
    }
  }


  &__logo {
    position: absolute;
    right: 15px;
    bottom: 15px;
    max-width: 90px;
    max-height: 40px;
    @media (--tablet-sm-and-less) {
      right: 10px;
      bottom: 10px;
    }
    @media (--mobile) {
      max-width: 80px;
      max-height: 35px;
    }
    @media (--mobile-sm) {
      max-width: 70px;
      max-height: 30px;
    }
  }
}

